import React, { Component } from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import axios from 'axios';
import displayIf from './display_if';
import { Login } from './login';
import { BellIcon, UserIcon } from '@heroicons/react/solid';
import { TagId } from './types';
import { buildApiUrl, isDevelopment } from './configure';
import { maybePluralize } from './dates';
import { Feedback } from './feedback';
import { MobileView } from 'react-device-detect';
import { classNames } from './tailwind';

type HeaderProps = {
  logoutFn: () => void,
  hasExtensionCb?: (hasExtension: boolean) => void,
  username: string,
  loggedIn: boolean,
  signupToken?: string,
  initiallyShowLoginOverlay: boolean,
  userImageUrl: string,
  uploadNewScreenshot?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onDismissLoginOverlay?: () => void,
}

type HeaderState = {
  showLoginOverlay: boolean,
  notifiedTags: Array<TagId>,
  inIframe: boolean,
  giveFeedback: boolean,
}

function inIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

export class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      showLoginOverlay: this.props.initiallyShowLoginOverlay,
      notifiedTags: [],
      inIframe: inIframe(),
      giveFeedback: false,
    }
  }

  componentDidUpdate(prevProps: HeaderProps, prevState: HeaderState) {
    if (prevProps.initiallyShowLoginOverlay !== this.props.initiallyShowLoginOverlay) {
      this.setState({
        showLoginOverlay: this.props.initiallyShowLoginOverlay,
      });
    }
  }
  logout = () => {
    if (this.state.inIframe) {
      window.open("/welcome?login=true", "_blank")?.focus();
      return;
    }
    this.props.logoutFn();
    axios({
        url: '/logout',
        method: 'post',
    }).then(function (response) {
        window.location.pathname = '/welcome';
    });
  }

  login = () => {
    if (this.state.inIframe) {
      window.open("/welcome?login=true", "_blank")?.focus();
    } else {
      this.setState({
        showLoginOverlay: true,
      });
    }
  }

  stopLoggingIn = () => {
    this.setState({
      showLoginOverlay: false,
    });
    if (this.props.onDismissLoginOverlay) {
      this.props.onDismissLoginOverlay();
    }
  }

  componentDidMount() {
    axios({
      url: '/get_notifications',
      method: 'get',
    }).then((response) => {
      let notifiedTags: Array<TagId> = response.data.notified_tags;
      const currentTag = new URL(window.location.href).searchParams.get('tag') ?? "0";
      this.setState({
        notifiedTags: notifiedTags.filter((t) => {return t.toString() !== currentTag}),
      });
      // Ping the extension
      const extensionId: string = isDevelopment() ?
          'ffodofhldheflloibnceeanmgegfomcb' :
          'apomjoebmhkipmpoodjbhiibekmpjldd';
      console.log("Pinging extension: ", extensionId);
      if (chrome.runtime === undefined) {
        if (this.props.hasExtensionCb !== undefined) {
          this.props.hasExtensionCb(false);
        }
        console.log("No extension callback");
        return;
      }
      chrome.runtime.sendMessage(extensionId, {
        message: 'version',
        notification_request_url: buildApiUrl('/notificationz?k=' + response.data.notification_key),
        // notification_request_url: 'https://api.marq.link/api/notificationz?k=' + response.data.notification_key,
      },
        (reply) => {
          console.log("Got extension reply");
          let hasExtension = false;
          if (!chrome.runtime.lastError &&
              reply &&
              reply.version &&
              reply.version >= "0.0.2") {
              hasExtension = true;
          } else {
            console.log("Extension error: ", chrome.runtime.lastError);
            console.log("Extension reply: ", reply);
          }
          if (this.props.hasExtensionCb !== undefined) {
            this.props.hasExtensionCb(hasExtension);
          }
      });
    })
  }

  maybeRedirectTarget() {
    return this.state.inIframe ? "_blank" : "";
  }

  maybeRedirectReferrer() {
    return this.state.inIframe ? "noreferrer" : "";
  }

  openFeedback = () => {
    this.setState({giveFeedback: true});
  }

  closeFeedback = () => {
    this.setState({giveFeedback: false});
  }

  render() {
    return (<div>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {/* <img
                      className="block lg:hidden h-8 w-auto"
                      src="favicon.ico"
                      alt="Marq Link"
                    /> */}
                    <div className="text-white text-2xl">
                      <a href="/"
                        target={this.maybeRedirectTarget()}
                        rel={this.maybeRedirectReferrer()}
                      >Marq Link</a>
                    </div>
                    {/* <img
                      className="block lg:hidden h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                      alt="Marq Link"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                      alt="Marq Link"
                    /> */}
                  </div>
                  <div className="ml-6">
                    <div className="flex space-x-4">
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a href="/" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                        target={this.maybeRedirectTarget()}
                        rel={this.maybeRedirectReferrer()}
                      >
                        All Tags
                      </a>
                      <a href="/notified_tags" className={classNames(
                          this.state.notifiedTags.length > 0 ? "bg-green-400" : "bg-gray-900",
                          "text-white px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          target={this.maybeRedirectTarget()}
                          rel={this.maybeRedirectReferrer()}
                        >
                          <BellIcon className="sm:hidden -ml-0.5 mr-0.5 pt-0.5 h-4 w-4" aria-hidden="true" />
                          <span className="hidden sm:block">
                            {maybePluralize(this.state.notifiedTags.length, 'Notification')}
                          </span>
                      </a>
                      <button className="hidden sm:block bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                        onClick={this.openFeedback}
                      >
                        Feedback
                      </button>
                    </div>
                  </div>
                  <MobileView>
                      {this.props.uploadNewScreenshot === undefined ? null : (
                        <div className="ml-6 bg-blue-500 text-white w-full text-sm rounded m-1 p-2">
                            <label htmlFor="file-upload">
                                Create Link
                                <input id="file-upload" type="file" accept="image/*" onChange={(event) => this.props.uploadNewScreenshot(event)} 
                                    style={{display: 'none'}}
                                />
                            </label>
                        </div>
                      )}
                  </MobileView>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div style={displayIf(!this.props.loggedIn)} className="flex items-center">
                        <button className="text-gray-100 px-4 py-2 bg-gray-600 flex text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                          onClick={this.login}
                        >
                          <UserIcon className="-ml-1 mr-2 h-5 w-5 text-gray-300" aria-hidden="true" />
                          Sign Up or Log In
                        </button>
                  </div>
                  <div style={displayIf(this.props.loggedIn)} className="flex items-center">

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <span className="text-gray-100 p-1">Logged in as {this.props.username}</span>
                          <img
                            className="ml-1 h-8 w-8 rounded-full"
                            src={this.props.userImageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 w-full'
                                )}
                                href="/profile"
                              >
                                Profile
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 w-full'
                                )}
                                onClick={this.logout}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                <Disclosure.Button
                  as="a"
                  href="/"
                  className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  target={this.maybeRedirectTarget()}
                  rel={this.maybeRedirectReferrer()}
                
                >
                  All Tags
                </Disclosure.Button>
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex justify-center px-5">
                  {/* <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div> */}
                    <div className="text-base font-medium text-white">{this.props.loggedIn ? ("Logged in as " + this.props.username) : "Logged out"}</div>
                    {/* <div className="text-sm font-medium text-gray-400">{this.props.email}</div> */}
                  </div>
                <div className="mt-3 px-2 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href="/profile"
                    style={displayIf(this.props.loggedIn)}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    Your Profile
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="#"
                    onClick={this.logout}
                    style={displayIf(this.props.loggedIn)}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    Sign out
                  </Disclosure.Button><Disclosure.Button
                    as="a"
                    href="#"
                    onClick={this.login}
                    style={displayIf(!this.props.loggedIn)}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    Sign Up or Log In
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Login
          stopLoggingIn={this.stopLoggingIn}
          showLoginOverlay={this.state.showLoginOverlay}
          signupToken={this.props.signupToken !== undefined ? this.props.signupToken : ""}
      />
      <Feedback
          closeFeedback={this.closeFeedback}
          enabled={this.state.giveFeedback}
      />
      </div>
    )
  }
}