import React, { Component } from "react";
import { maybePluralize, timeSince } from "./dates";
import { Paginator } from "./paginator";
import { classNames } from "./tailwind";
import { TagDetails, TagId } from "./types";


type ListTagsProps = {
    tagDetails: Array<TagDetails>,
    notifications: boolean,
}
type ListTagsState = {
  currentPage: number,
}

function navigateToTag(tagId: TagId) {
    window.location.href = "/tag?tag=" + tagId;
}

export class ListTags extends Component<ListTagsProps, ListTagsState> {
    constructor(props: ListTagsProps) {
        super(props);
        this.state = {
            currentPage: 0,
        }
    }

    componentDidMount() {
      window.addEventListener("hashchange", () => {
          this.updatePagination();
      });
      this.updatePagination();
  }

  updatePagination = () => {
      const hash = window.location.hash.substring(1);
      hash.split('&').forEach(element => {
          let parts = element.split('=');
          if (parts.length !== 2) return;
          if (parts[0] === 'page') {
              this.setState({
                  currentPage: +parts[1],
              });
              return;
          }
      });
  }

    pageSize() {
      // TODO: extract from hash
      return 30;
    }

    render() {
      const allTags = this.props.tagDetails
        .filter((tag) => {return !this.props.notifications || tag.notifications > 0});
      const paginatedTags = allTags
        .slice(this.state.currentPage * this.pageSize(),
                (this.state.currentPage + 1) * this.pageSize());
      return (
        <div className="mt-4">
        <div className="px-4 sm:px-6 lg:px-8 sm:flex text-left">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{this.props.notifications ? 'Notified ' : ''}Tags</h1>
            <p className="mt-2 text-sm text-gray-700">
              {this.props.notifications ? 'Tags that have been edited by another user since you last viewed them.' :
                'All recently edited tags you have access to.'}
            </p>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      </th>
                      <th scope="col" className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="sm:hidden pl-0 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Stats
                      </th>
                      <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Notifications
                      </th>
                      <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Last Edited
                      </th>
                      <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Users
                      </th>
                      <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Links
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {paginatedTags.map((tag: TagDetails) => (
                      <tr key={tag.tagId}
                        className="hover:bg-gray-200 text-left cursor-pointer"
                        onClick={() => navigateToTag(tag.tagId)}
                      >
                        <td className="whitespace-normal w-20 py-4 pl-2 pr-0 text-sm">
                          <div className="flex align-middle">
                            <div className="w-full sm:w-36 flex-shrink-0">
                              <img className="object-cover rounded-sm" src={tag.screenshotUrl} alt="" />
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-normal w-4 sm:w-full py-4 pl-2 pr-3 text-sm sm:pl-6">
                              <div className="font-medium text-gray-900">{tag.name}</div>
                              <div className="text-gray-500">{tag.description}</div>
                        </td>
                        <td className="sm:hidden whitespace-normal w-4 py-4 pl-0 pr-3 text-sm">
                            <div className={classNames(tag.notifications > 0 ? "bg-green-300 font-medium p-1 mr-1 rounded text-white" : "text-gray-900")}>
                              {maybePluralize(tag.notifications, "new change")}
                            </div>
                            <div className="text-gray-500">{timeSince(new Date(tag.lastEditTime))} ago</div>
                            <div className="text-gray-500">{maybePluralize(tag.editors, "user")}</div>
                            <div className="text-gray-500">{maybePluralize(tag.threadCount, "link")}</div>
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className={classNames(tag.notifications > 0 ? "bg-green-300 font-medium p-2 rounded text-white" : "text-gray-900")}>
                            {maybePluralize(tag.notifications, "new change")}
                          </div>
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">{timeSince(new Date(tag.lastEditTime))} ago</div>
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">{tag.editors}</td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">{tag.threadCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {Paginator(allTags.length, this.state.currentPage, this.pageSize())}
              </div>
            </div>
          </div>
        </div>
      </div>
      );
  }
}