export type ThreadId = number;

export type Reaction = {
    username: string,
    emojiCode: string,
}

export type Comment = {
    author: string,
    text: string,
    editTime: number,
    tags: Array<string>,
    reactions: Array<Reaction>,
}

export type Anchor = {
    highlightedText: string,
    parentDivId: string,
    // text containing the highlight
    anchorData: string,
    anchorType: number,
    // div, p, etc
    anchorName: string,
    anchorParentClass: string,
    anchorNodeName: string,
}

export type SelectionBox = {
    top: number,
    left: number,
    width: number,
    height: number,
}

export type TagId = number;
export type ScreenshotId = number;

export type Thread = {
    comments: Array<Comment>,
    anchor: Anchor,
    threadId: ThreadId,
    users: Array<string>,
    archived: boolean | undefined,
    tagIds: Array<TagId>,
    lastEditTime: number,
    creationTime: number,
}

export type UserDetails = {
    username: string,
    userImageUrl: string,
    email: string,
}

export type TagDetails = {
    name: string,
    description: string,
    threadCount: number,
    lastEditTime: Date,
    tagId: TagId,
    editors: number,
    screenshotUrl: string,
    notifications: number,
    ordering: string,
}

export type PageThread = {
    thread: Thread,
    url: string,
    screenshotUrl: string,
    screenshot: string,
    markup: Array<DrawnItem>,
}

export function lastEditTime(thread: Thread) {
    let lastTime = thread.creationTime;
    // thread.comments.forEach(c => {
    //     lastTime = Math.max(lastTime, c.editTime);
    // });
    return lastTime;
}

export type Editor = {
    id: number,
    first_name: string,
    last_name: string,
    email: string,
}

export enum DrawingType {
    Edit = 0,
    Line = 1,
    Arrow = 2,
    Rectangle = 3,
    Text = 4,
    Circle = 5,
}

export type DrawnItem = {
    color: string,
    user: UserDetails,
    drawingType: DrawingType,
    lineWidth: number
}
export type DrawnLine = DrawnItem & {
    x0: number,
    y0: number,
    x1: number,
    y1: number,
}
export type DrawnArrow = DrawnLine & {}
export type DrawnRectangle = DrawnLine & {}
export type DrawnText = DrawnItem & {
    x0: number,
    y0: number,
    x1: number,
    font: string,
    size: number,
    text: string,
    bold: boolean,
    editable: boolean,
    showBorder: boolean,
}