import React, { Component } from 'react';
import { Fragment } from 'react'
import axios from 'axios';
import { LoginState, setLoginState, userDetails } from "./user";
import { AddTag } from "./add_tag";

import { Anchor, DrawnItem, TagDetails, TagId, Thread, ThreadId } from './types';
import { TagChip } from './tag_chip';
import { ShareDialog } from './share_dialog';
import { CheckIcon, UsersIcon } from '@heroicons/react/solid';
import { ScreenshotMarkup } from './screenshot_markup';
import { Switch } from '@headlessui/react';
import { classNames } from './tailwind';
import { MarkedUpImage } from './marked_up_image';
import Cookies from 'universal-cookie';
import * as amplitude from '@amplitude/analytics-browser';

export type ScreenshotBundle = {
    data: string,
    width: number,
    height: number,
}
export type Screenshot = {
    primary: ScreenshotBundle,
    alternates: Array<ScreenshotBundle>,
}

type NewThreadProps = {
    redoCrop?: () => void,
    allTags: Array<TagDetails>,
    refreshTagsFn: () => void,
    screenshot: Screenshot,
    sourceUrl: string,
    // If true, it's an upload (probably mobile), not a screenshot.
    isUpload: boolean,
};
type NewThreadState = LoginState & {
    newCommentText: string,
    promptLogin: boolean,
    tagIds: Array<TagId>,
    share: boolean,
    stage: ScreenshotStage,
    drawings: Array<DrawnItem>,
    visitMarqLinkOnSave: boolean,
};

function TODODummyAnchor() {
    let anchor: Anchor = {
        highlightedText: '',
        parentDivId: 'fake',
        anchorData: 'fake',
        anchorType: 0,
        anchorName: 'fake',
        anchorParentClass: 'fake',
        anchorNodeName: 'fake',
    }
    return anchor;
}

enum ScreenshotStage {
    Crop = 0,
    Markup = 1,
    Tag = 2,
    Comment = 3,
    Saved = 4,
}

type ScreenshotStep = {
    name: string,
    id: ScreenshotStage,
}


const loginSteps: Array<ScreenshotStep> = [
    {
        name: "Crop Screenshot",
        id: ScreenshotStage.Crop,
    },
    {
        name: "Tag Screenshot",
        id: ScreenshotStage.Tag,
    },
    {
        name: "Save Marq Link",
        id: ScreenshotStage.Comment,
    },
];

function stageUrl(id: ScreenshotStage) {
    return "#" + id;
}

const cookies = new Cookies();

export class TuiNewThread extends Component<NewThreadProps, NewThreadState> {
    constructor(props: NewThreadProps) {
        super(props);
        let visitMarqLinkOnSave = cookies.get("visitMarqLinkOnSave");
        visitMarqLinkOnSave = (visitMarqLinkOnSave === undefined) || (visitMarqLinkOnSave === "true");
        this.state = {
            newCommentText: "",
            promptLogin: false,
            tagIds: [],
            loggedIn: false,
            username: "",
            email: "",
            logoutFn: () => {},
            userImageUrl: '',
            share: false,
            stage: ScreenshotStage.Tag,
            drawings: [],
            visitMarqLinkOnSave: visitMarqLinkOnSave,
        }
        window.location.href = stageUrl(ScreenshotStage.Tag);
    }

    onHashChange = () => {
        let newStage = +window.location.hash[1] as ScreenshotStage;
        if (newStage >= ScreenshotStage.Comment && this.state.tagIds.length === 0) return;
        if (newStage === ScreenshotStage.Crop) {
            if (!this.props.redoCrop) return;
            this.props.redoCrop();
        }
        this.setState({
            stage: newStage,
        })
    }

    componentDidMount() {
        setLoginState(this);
        window.onhashchange = this.onHashChange;
    }

    componentWillUnmount() {
        window.onhashchange = null;
    }

    unTag = (tagId: TagId) => {
        let tagIds = this.state.tagIds.filter(t => t !== tagId);
        this.setState({
            tagIds: tagIds,
        },
        () => {
            if (tagIds.length === 0) {
                window.location.href = stageUrl(ScreenshotStage.Tag);
                this.setState({
                    stage: ScreenshotStage.Tag,
                });
            }
        });
    }

    handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            newCommentText: event.target.value,
        });
    }

    addTag = (tagId: TagId) => {
        let tagIds = this.state.tagIds;
        tagIds.push(tagId);
        this.setState({
            tagIds: tagIds,
            stage: ScreenshotStage.Comment,
        });
    }

    submitNewTag = (name: string, description: string) => {
        axios({
            url: '/create_tag',
            method: "POST",
            data: JSON.stringify({
                name: name,
                description: description,
            }),
        }).then(response => {
            this.props.refreshTagsFn();
            const newTagId = response.data.tagId;
            this.addTag(newTagId);
            amplitude.track('New Tag while Screenshotting');
        });
    }

    async createThreadWithScreenshot(snapshot: Screenshot): Promise<ThreadId> {
        const thread: Thread = {
            comments: [{
                author: this.state.username,
                text: this.state.newCommentText,
                editTime: new Date().getTime(),
                tags: [],
                reactions: [],
            }],
            anchor: TODODummyAnchor(),
            threadId: 0,
            users: [],
            archived: false,
            tagIds: [],
            lastEditTime: 0,
            creationTime: 0,
        };
        let response = await axios({
            url: '/create_thread',
            method: 'post',
            data: JSON.stringify({
                page_url: this.props.sourceUrl,
                thread: thread,
            }),
            //    headers: { 'Accept': 'application/json', 'Content-TyparchivedCounte': 'application/json'} })).json();
        });
        thread.threadId = response.data.threadId;
        await axios({
            method: 'post',
            url: '/attach_screenshots',
            data: JSON.stringify({
                thread_id: response.data.threadId,
                screenshots: snapshot,
            })
        });
        amplitude.track('Saved Screenshot');
        return thread.threadId;
    }

    async save() {
        const tagIds = this.state.tagIds;
        if (tagIds.length === 0) return false;
        const threadId = await this.createThreadWithScreenshot(this.props.screenshot);
        if (threadId === 0) return false;
        for (let i = 0; i < tagIds.length; i++) {
            await axios({
                url: '/add_tag',
                method: "POST",
                data: JSON.stringify({
                    thread_id: threadId,
                    tag_id: tagIds[i],
                }),
            });
        }
        if (this.state.drawings.length > 0) {
            await axios({
                url: '/update_markup',
                method: "POST",
                data: JSON.stringify({
                    thread_id: threadId,
                    markup: this.state.drawings,
                }),
            });
        }
        return true;
    }
    close() {
        window.top?.close();
    }
    setVisitMarqLinkEnabled = (enabled: boolean) => {
        cookies.set("visitMarqLinkOnSave", enabled,
            {
                path: "/",
                secure: true,
                httpOnly: false,
                sameSite: "none",
            });
        this.setState({
            visitMarqLinkOnSave: enabled,
        });
    }
    saveMarqLink = () => {
        this.save().then((success: boolean) => {
            if (!success) return;
            if (this.state.visitMarqLinkOnSave || this.props.isUpload) {
                window.top?.location.replace(window.location.origin + "/tag/?tag=" + this.state.tagIds[0]);
            } else {
                this.close();
            }
        });
    }
    cancel = () => {
        window.top?.close();
    }
    openShare = () => {
        this.setState({
            share: true,
        });
    }
    closeShare = () => {
        this.setState({
            share: false,
        });
    }
    closeMarkup = () => {
        window.location.href = stageUrl(this.state.tagIds.length === 0 ? ScreenshotStage.Tag : ScreenshotStage.Comment);
    }
    openMarkup = () => {
        window.location.href = stageUrl(ScreenshotStage.Markup);
    }

    updateDrawings = (drawings: Array<DrawnItem>) => {
        this.setState({
            drawings: drawings,
        });
        this.closeMarkup();
    }

    canClick(stageId: ScreenshotStage) {
        switch(stageId) {
            case ScreenshotStage.Crop:
                return true;
            case ScreenshotStage.Markup:
                return true;
            case ScreenshotStage.Tag:
                return this.state.tagIds.length === 0;
            case ScreenshotStage.Comment:
                return this.state.tagIds.length > 0;
            default:
                return false;
        }
    }

    render() {
        let lastTagDetails: TagDetails | null = null;
        for (let i = 0; i < this.state.tagIds.length; i++) {
            let matches = this.props.allTags.filter(tag => tag.tagId === this.state.tagIds[i]);
            if (matches.length === 1) {
                lastTagDetails = matches[0];
            }
        }
        const shareDialog = lastTagDetails === null ? Fragment :
            <ShareDialog
                sharing={this.state.share}
                tagDetails={lastTagDetails}
                closeShareFn={this.closeShare}
                refreshTags={this.props.refreshTagsFn}
            />;
        return (
            <div>
                <div className="flex flex-col justify-between text-left font-normal mb-3">
                    <div className="text-lg">
                        Upload your screenshot to
                        <a href="/"
                            className="ml-1 hover:underline">www.marq.link</a>
                        <div className="text-sm text-gray-500 my-1">
                            Your screenshot and tags are private by default.
                            You can share tags, letting others view and comment on your links.
                        </div>
                    </div>
                    <div>
                        <nav aria-label="Progress">
                            <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                                {loginSteps.map((step, stepIdx) => (
                                <li key={step.name} className="relative md:flex-1 md:flex">
                                    {step.id < this.state.stage ? (
                                    <a href={stageUrl(step.id)} className={classNames("group flex items-center w-full", this.canClick(step.id) ? "" : "pointer-events-none")}>
                                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                            <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                        </span>
                                    </a>
                                    ) : step.id === this.state.stage ? (
                                    <a href={stageUrl(step.id)} className="px-6 py-4 flex items-center text-sm font-medium pointer-events-none" aria-current="step">
                                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                                        <span className="text-indigo-600">{stepIdx}</span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                                    </a>
                                    ) : (
                                    <a href={stageUrl(step.id)} className={classNames("group flex items-center", this.canClick(step.id) ? "" : "pointer-events-none")}>
                                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">{stepIdx}</span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                                        </span>
                                    </a>
                                    )}

                                    {stepIdx !== loginSteps.length - 1 ? (
                                    <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                                        <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                        >
                                            <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                            />
                                        </svg>
                                        </div>
                                    </>
                                    ) : null}
                                </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                </div>
                {this.state.stage < ScreenshotStage.Comment ? null : (
                    <div>
                        <div>Tags</div>
                        {shareDialog}
                    </div>
                )}
                {this.props.allTags.filter(t => this.state.tagIds.includes(t.tagId)).map(t => {
                    return (
                        <div
                            key={t.tagId}
                            className="flex flex-row"
                            >
                            <div className="flex-grow">
                                <TagChip
                                    tagDetails={t}
                                    removeTag={() => this.unTag(t.tagId)}
                                    bigClickBox={false}
                                />
                            </div>
                            <button
                            type="button"
                            onClick={this.openShare}
                            className="inline-flex items-center py-2 px-3 my-2 ml-1 border border-transparent rounded-full bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <UsersIcon className="-ml-1 mr-1 h-5 w-5 text-white" aria-hidden="true" />
                                <span className="text-sm md:block hidden font-medium text-white">
                                    Share
                                </span>
                            </button>
                        </div>
                    );
                })}
                <AddTag
                    userTags={this.props.allTags}
                    alreadyAddedTagIds={this.state.tagIds}
                    postTag={this.addTag}
                    submitNewTag={this.submitNewTag}
                    numToShow={5}
                    showTagsByDefault={this.state.stage === ScreenshotStage.Tag}
                />
                {this.state.stage !== ScreenshotStage.Comment ? null : (
                <div className="md:grid md:grid-cols-5 md:gap-6">
                <div className="md:col-span-3 justify-center">
                    <h3 className="text-md font-normal leading-6 text-gray-900 my-2">Screenshot</h3>

                    <div className="relative group cursor-pointer">
                        <div className="absolute top-0 left-0 opacity-0 group-hover:opacity-50 z-10 bg-white text-center
                            w-full font-black pointer-events-none">
                            Annotate your screenshot
                        </div>
                        <MarkedUpImage
                                imageSource={this.props.screenshot.primary.data}
                                markup={this.state.drawings}
                                onClick={this.openMarkup}
                                showTextEdit={false}
                                locatorLength={0}
                            />
                    </div>
                </div>
                <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                    <div>
                    <label htmlFor="about" className="block text-md font-normal text-gray-900 mt-2 mb-3">
                        Comment (optional)
                    </label>
                    <div className="mt-1">
                        <textarea
                        id="about"
                        name="about"
                        value={this.state.newCommentText}
                        onChange={this.handleOnChange}
                        rows={3}
                        className="p-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder="Add a comment..."
                        />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">Add a comment to explain what you're saving.</p>
                    </div>
                    {this.props.isUpload ? null : (
                    <Switch.Group as="div" className="flex items-center justify-between">
                        <span className="flex-grow flex flex-col">
                            <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                            View your tag on marq.link after saving.
                            </Switch.Label>
                            <Switch.Description as="span" className="text-sm text-gray-500">
                            Otherwise, you'll go back to the page you screenshotted.
                            </Switch.Description>
                        </span>
                        <Switch
                            checked={this.state.visitMarqLinkOnSave}
                            onChange={this.setVisitMarqLinkEnabled}
                            className={classNames(
                                this.state.visitMarqLinkOnSave ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            )}
                        >
                            <span
                            aria-hidden="true"
                            className={classNames(
                                this.state.visitMarqLinkOnSave ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                            />
                        </Switch>
                    </Switch.Group>)}
                    <button
                        className="flex mt-3 bg-indigo-500 p-4 mx-auto rounded-md shadow-sm text-md leading-4 font-medium text-white hover:bg-indigo-700"
                        onClick={this.saveMarqLink}>
                        Save and upload your new Marq Link
                    </button>
                </div>
                </div>)}
                <ScreenshotMarkup
                    showEditOverlay={this.state.stage === ScreenshotStage.Markup}
                    stopShowingEditOverlay={this.closeMarkup}
                    updateDrawings={this.updateDrawings}
                    imageSource={this.props.screenshot.primary.data}
                    user={userDetails(this.state)}
                    drawnItems={this.state.drawings}
                    showLogin={() => {}}
                    author={""}
                />
                <div className="flex justify-between">
                    <button
                        className="mt-3 bg-red-700 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white hover:bg-red-800"
                        onClick={this.cancel}>
                        Cancel Screenshot
                    </button>
                </div>
                
            </div>
        );
    }
}