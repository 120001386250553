import axios, { AxiosRequestConfig } from 'axios';
import React, { Component } from 'react';
import { buildApiUrl } from './configure';
import { transition } from './transitions';
import * as amplitude from '@amplitude/analytics-browser';

type LoginProps = {
    stopLoggingIn: () => void,
    showLoginOverlay: boolean,
    signupToken: string,
}

type EmailSuccessMessage = {
    title: string,
    details: string,
}

enum LoginMode {
    Login = 0,
    Signup = 1,
    Notification = 2,
    ForgotPassword = 3,
    ResetPassword = 4,
}

type LoginWidgetState = {
    usernameEntry: string,
    passwordEntry: string,
    passwordEntryMatch: string,
    agreeToTerms: boolean,
    emailLoginClickable: boolean,
    firstName: string,
    lastName: string,
    failedLogin: string,
    emailSentMessage: EmailSuccessMessage | null,
    resetPasswordToken: string,
    mode: LoginMode,
}

export class Login extends Component<LoginProps, LoginWidgetState> {
    constructor(props: LoginProps) {
        super(props);
        const url = new URL(window.location.href);
        const resetPasswordToken = url.searchParams.get("reset_password_key");
        let mode: LoginMode = LoginMode.Login;
        if (resetPasswordToken !== null) {
            mode = LoginMode.ResetPassword;
        }
        this.state = {
            usernameEntry: "",
            passwordEntry: "",
            passwordEntryMatch: "",
            agreeToTerms: false,
            emailLoginClickable: false,
            firstName: "",
            lastName: "",
            failedLogin: "",
            emailSentMessage: null,
            resetPasswordToken: resetPasswordToken ?? "",
            mode: mode,
        }
    }
    createAccountToSignIn = () => {
        this.setState({
            mode: LoginMode.Login,
        });
    }
    ignoreClick = (event) => {
        event.cancelBubble = true;
        if (event.stopPropagation) {
            event.stopPropagation();
        }
    }
    googleLogin = () => {
        amplitude.track('Login With Google');
        window.location.href = buildApiUrl('/google_login_redirect?next='
            + encodeURIComponent(window.location.href));
    }
    updateUsername = (event: React.ChangeEvent<HTMLInputElement>)  => {
        this.setState({
            usernameEntry: event.target.value
        });
    }
    updatePassword = (event: React.ChangeEvent<HTMLInputElement>)  => {
        this.setState({
            passwordEntry: event.target.value,
        });
    }
    updatePasswordMatch = (event: React.ChangeEvent<HTMLInputElement>)  => {
        this.setState({
            passwordEntryMatch: event.target.value,
        });
    }
    createAccount = (event: React.MouseEvent) => {
        this.setState({
            mode: LoginMode.Signup,
        })
    }
    updateAgreeToTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            agreeToTerms: !this.state.agreeToTerms,
        });
    }
    emailLoginClickable = () => {
        if (this.state.passwordEntry.length < 6) {
            return "Password is too short.";
        }
        if (!this.state.usernameEntry.includes("@")) {
            return "Invalid email address."
        }
        if (this.state.mode === LoginMode.Signup) {
            if (this.state.passwordEntry !== this.state.passwordEntryMatch) {
                return "Passwords do not match.";
            }
            if (!this.state.agreeToTerms) {
                return "You must agree to the terms and privacy policy to create an account";
            }
        }
        return "";
    }
    updateFirstName = (event: React.ChangeEvent<HTMLInputElement>)  => {
        this.setState({
            firstName: event.target.value,
        });
    }
    updateLastName = (event: React.ChangeEvent<HTMLInputElement>)  => {
        this.setState({
            lastName: event.target.value,
        });
    }
    signInWithEmail = (event: React.MouseEvent) => {
        const errors = this.emailLoginClickable();
        if (errors) {
            this.setState({
                failedLogin: errors,
            });
            return;
        }
        let request: AxiosRequestConfig<any> = {
            method: 'post',
            url: '/login_email_user',
            data: JSON.stringify({
                email: this.state.usernameEntry,
                password: this.state.passwordEntry,
            }),
        };
        if (this.props.signupToken !== "") {
            request = {
                method: 'post',
                url: '/confirm_email_user',
                data: JSON.stringify({
                    email: this.state.usernameEntry,
                    password: this.state.passwordEntry,
                    token: this.props.signupToken,
                }),
            };
            amplitude.track('Login Confirm Email User');
        }
        axios(request).then(() => {
            const nextPage = new URL(window.location.href).searchParams.get('next');
            if (nextPage === null) {
                if (this.props.signupToken !== "") {
                    // Don't just reload; drop the token.
                    window.location.href = "/";
                } else {
                    window.location.reload();
                }
            } else {
                window.location.href = nextPage;
            }
        }).catch((reason: any) => {
            this.setState({
                failedLogin: "Incorrect email or password",
            });
        });
    }
    signUp = (event: React.MouseEvent) => {
        const errors = this.emailLoginClickable();
        if (errors) {
            this.setState({
                failedLogin: errors,
            });
            return;
        }
        axios({
            method: 'post',
            url: '/create_email_user',
            data: JSON.stringify({
                email: this.state.usernameEntry,
                password: this.state.passwordEntry,
                given_name: this.state.firstName,
                family_name: this.state.lastName,
                tag_key: new URL(window.location.href).searchParams.get("key"),
            }),
        }).then(() => {
            amplitude.track('Login Create Email User');
            this.setState({
                mode: LoginMode.Notification,
                emailSentMessage: {
                    title: "Signup Successful!",
                    details: "Follow the link sent to your email address to finish signing up.",
                },
                failedLogin: "",
            });
        }).catch((reason: any) => {
            this.setState({
                failedLogin: "Invalid email; may already exist",
            });
        });
    }

    forgotPassword = () => {
        this.setState({
            mode: LoginMode.ForgotPassword,
        });
    }

    sendResetPasswordEmail = () => {
        axios({
            method: 'post',
            url: '/forgot_password',
            data: JSON.stringify({
                email: this.state.usernameEntry,
            }),
        }).then(() => {
            this.setState({
                mode: LoginMode.Notification,
                emailSentMessage: {
                    title: "Password reset email sent",
                    details: "Check your email and follow the link provided. If the email is not in our system or not clicked, no account changes will be made.",
                },
            });
        });
    }

    resetPassword = () => {
        axios({
            method: 'post',
            url: '/reset_password',
            data: JSON.stringify({
                password: this.state.passwordEntry,
                reset_password_key: this.state.resetPasswordToken,
            }),
        }).then(() => {
            this.setState({
                mode: LoginMode.Notification,
                emailSentMessage: {
                    title: "Password successfully reset",
                    details: "You can now log in with your new password",
                },
            });
        }).catch((error) => {
            this.setState({
                mode: LoginMode.Notification,
                emailSentMessage: {
                    title: "Failed to reset password",
                    details: "The link you followed to get here was invalid and may have expired.",
                },
            });
        });
    }

    renderLogin(): JSX.Element {
        return (
            <div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{this.props.signupToken === "" ? "Sign in to your account" : "Sign in to finish creating your account"}</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Or{' '}
                        <button
                            onClick={this.createAccount}
                            className="font-medium text-indigo-600 hover:text-indigo-500">
                        sign up
                        </button>
                    </p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                        </label>
                        <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={this.state.usernameEntry}
                            onChange={this.updateUsername}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                        </label>
                        <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={this.state.passwordEntry}
                            onChange={this.updatePassword}
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        {/* <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                            Remember me
                        </label>
                        </div> */}

                        <div className="text-sm">
                        <button
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={this.forgotPassword}
                        >
                            Forgot your password?
                        </button>
                        </div>
                    </div>
                    <div className="flex m-4 justify-center text-red-500">
                        {this.state.failedLogin}
                    </div>

                    <div>
                        <button
                        onClick={this.signInWithEmail}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Sign in
                        </button>
                    </div>
                    </div>

                    <div className="mt-6">
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or</span>
                        </div>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-3">
                        <button
                                className="flex border border-gray-300 hover:bg-gray-200 p-2 font-medium rounded items-center"
                                onClick={this.googleLogin}>
                            {/* <img src='https://d308zvlq21xad2.cloudfront.net/images/btn_google_signin_light_normal_web.png' /> */}
                            <img className='px-2' src='https://d308zvlq21xad2.cloudfront.net/images/btn_google_light_normal_ios.svg'
                                alt="Google login icon" />
                            Continue with Google
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }

    renderSignup(): JSX.Element {
        return (
            <div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Sign up for Marq Link</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Or{' '}
                        <button
                            onClick={this.createAccountToSignIn}
                            className="font-medium text-indigo-600 hover:text-indigo-500">
                            sign in with an existing account
                        </button>
                    </p>
                </div>

                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-2">
                    <div>
                        <label htmlFor="given-name" className="block text-sm font-medium text-gray-700">
                        First Name
                        </label>
                        <div className="mt-1">
                        <input
                            id="fname"
                            name="fname"
                            type="text"
                            autoComplete="given-name"
                            value={this.state.firstName}
                            onChange={this.updateFirstName}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="family-name" className="block text-sm font-medium text-gray-700">
                        Last Name
                        </label>
                        <div className="mt-1">
                        <input
                            id="lname"
                            name="lname"
                            type="text"
                            autoComplete="family-name"
                            value={this.state.lastName}
                            onChange={this.updateLastName}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                        </label>
                        <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={this.state.usernameEntry}
                            onChange={this.updateUsername}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                        </label>
                        <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={this.state.passwordEntry}
                            onChange={this.updatePassword}
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Verify Password
                        </label>
                        <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={this.state.passwordEntryMatch}
                            onChange={this.updatePasswordMatch}
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            checked={this.state.agreeToTerms}
                            onChange={this.updateAgreeToTerms}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        I agree to the Marq Links 
                            <a className='underline mx-1' href='/terms'>Terms of Service</a>and
                            <a className='underline mx-1' href='/privacy'>Privacy Policy.</a>
                        </label>
                        </div>
                    </div>
                    <div className="flex m-4 justify-center text-red-500">
                        {this.state.failedLogin}
                    </div>

                    <div>
                        <button
                        onClick={this.signUp}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Sign up
                        </button>
                    </div>
                    </div>

                    <div className="mt-6">
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or</span>
                        </div>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-3">
                        <button
                                className="flex border border-gray-300 hover:bg-gray-200 p-2 font-medium rounded items-center"
                                onClick={this.googleLogin}>
                            {/* <img src='https://d308zvlq21xad2.cloudfront.net/images/btn_google_signin_light_normal_web.png' /> */}
                            <img
                                className='px-2'
                                src='https://d308zvlq21xad2.cloudfront.net/images/btn_google_light_normal_ios.svg'
                                alt="Google login icon"
                            />
                            Continue with Google
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }

    renderResetPassword(): JSX.Element {
        return (
            <div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
                </div>

                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-2">

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        New Password
                        </label>
                        <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={this.state.passwordEntry}
                            onChange={this.updatePassword}
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Verify Password
                        </label>
                        <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={this.state.passwordEntryMatch}
                            onChange={this.updatePasswordMatch}
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                    </div>

                    <div>
                        <button
                        onClick={this.resetPassword}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Update Password
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }

    stopLoggingIn = () => {
        this.props.stopLoggingIn();
        this.setState({
            mode: LoginMode.Login,
        });
    }

    transitionBlock(show: boolean, rendering: JSX.Element): JSX.Element {
        return transition(this.props.showLoginOverlay && show, this.stopLoggingIn,
            "sm:max-w-lg",
            rendering);
    }

    render() {
        return (
            <div>
                {this.transitionBlock(this.state.mode === LoginMode.Login, this.renderLogin())}
                {this.transitionBlock(this.state.mode === LoginMode.Signup, this.renderSignup())}
                {this.transitionBlock(this.state.mode === LoginMode.ResetPassword, this.renderResetPassword())}
                {this.transitionBlock(this.state.mode === LoginMode.Notification, (
                            <div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">{this.state.emailSentMessage?.title}</h2>
                                <p className="mt-2 text-center text-sm text-gray-600">
                                    {this.state.emailSentMessage?.details}
                                </p>
                                </div>

                                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                                
                                </div>
                            </div>))}
                {this.transitionBlock(this.state.mode === LoginMode.ForgotPassword, (
                            <div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>
                            <p className="mt-2 text-center text-sm text-gray-600">
                                Enter your email address to get a password reset link.
                            </p>
                            </div>

                            <div className="my-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Reset Password
                                </label>
                                <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={this.state.usernameEntry}
                                    onChange={this.updateUsername}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                            <div>
                                <button
                                onClick={this.sendResetPasswordEmail}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                Request password reset link
                                </button>
                            </div>

                            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                            
                            </div>
                        </div>))}
            </div>
        );
    }
}