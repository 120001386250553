import React, { Component } from 'react';
import { TagDetails } from './types';
import {
    PlusSmIcon,
    MinusSmIcon,
} from '@heroicons/react/solid';
import { maybePluralize, timeSince } from './dates';
import { classNames } from './tailwind';

type TagChipProps = {
    tagDetails: TagDetails,
    onClick?: () => void,
    removeTag?: () => void,
    bigClickBox: boolean,
}
type TagChipState = {
    showDetails: boolean,
}

export class TagChip extends Component<TagChipProps, TagChipState> {
    constructor(props: TagChipProps) {
        super(props);
        this.state = {
            showDetails: false,
        };
        console.log('Big click  box = ', this.props.bigClickBox);
    }

    mouseEnter = () => {
        this.setState({showDetails: true});
    }

    mouseLeave = () => {
        this.setState({showDetails: false});
    }

    onClick = () => {
        if (this.props.removeTag === undefined) {
            // Tag isn't added.
            this.props.onClick();
        } else {
            this.props.removeTag();
        }
    }

    dontPropagateClick = (event) => {
        event.stopPropagation();
    }

    render() {
        const tag = this.props.tagDetails;
        return (
            <div className={classNames("py-2 flex items-center justify-between space-x-3",
                    this.props.bigClickBox ? "hover:bg-indigo-300" : "")}
                    onClick={this.props.bigClickBox ? this.onClick : undefined}>
                <div className="hidden sm:block flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={tag.screenshotUrl} alt="" />
                </div>
                <div className="min-w-0 flex-1 sm:ml-3">
                    <div className="flex flex-row">
                        <a className="min-w-0 flex items-center text-left hover:underline"
                            href={'/tag?tag=' + tag.tagId}
                            target="_blank"
                            rel="noreferrer"
                            onClick={this.dontPropagateClick}
                        >
                            <p className="text-sm font-medium text-gray-900 truncate">{tag.name}</p>
                        </a>
                        <div className={classNames("flex-grow", this.props.bigClickBox ? "cursor-pointer" : "")}></div>
                    </div>
                    <p className={classNames("flex flex-wrap text-sm font-normal text-gray-500 divide-x divide-gray-400",
                            this.props.bigClickBox ? "cursor-pointer" : "")}>
                        <div className="pr-2">{timeSince(new Date(tag.lastEditTime))
                            } ago
                        </div>
                        <div className="px-2">{tag.editors > 1 ? 'Shared with ' + (tag.editors - 1) : 'Private'}
                        </div>
                        <div className="pl-2">{maybePluralize(tag.threadCount, 'link')}
                        </div>
                    </p>
                </div>
                {this.props.onClick !== undefined || this.props.removeTag !== undefined ? (
                    <div className="flex-shrink-0">
                        <button
                        type="button"
                        onClick={this.onClick}
                        className={classNames("inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                this.props.bigClickBox ? "" : "hover:bg-gray-200")}
                        >
                        {this.props.removeTag === undefined ?
                            <PlusSmIcon className="-ml-1 mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            :
                            <MinusSmIcon className="-ml-1 mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        }
                        <span className="text-sm md:block hidden font-medium text-gray-900">
                            {this.props.removeTag === undefined ? 'Apply' : 'Remove'}
                        </span>
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}