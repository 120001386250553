import { FiberManualRecord } from '@mui/icons-material';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import displayIf from './display_if';
import { TuiNewThread, Screenshot, ScreenshotBundle } from './tui_screenshot_form';
import { TagDetails, Thread } from './types';
import { LoginState, setLoginState } from './user';
import { Header } from './tui_header';
import { TagChip } from './tag_chip';
import * as amplitude from '@amplitude/analytics-browser';
import ReactTooltip from 'react-tooltip';

type ScreenshotPageProps = {
    screenshotDataUrl?: string,
    tags?: Array<TagDetails>,
};

type Selection = {
    x0: number,
    x1: number,
    y0: number,
    y1: number,
};
enum SelectionState {
    LOADING,
    STARTED,
    FIRST_POINT,
    FINISHED,
    CROPPED,
    MOBILE_UPLOAD_STARTED,
    SELECTION_SET_BY_EXTENSION,
}
type ScreenshotPageState = LoginState & {
    opacity: number,
    tags: Array<TagDetails>,
    selection: Selection,
    selectionState: SelectionState,
    originalScreenshot: string,
    sourceUrl: string,
    croppedSnapshot: Screenshot | null,
    userLookupDone: boolean,
}

function imageToScreenshotBundle(img: HTMLImageElement, x: number, y: number,
    width: number, height: number,
    targetWidth: number, targetHeight: number): ScreenshotBundle {
    let myCanvas = document.getElementById('my-canvas-id') as HTMLCanvasElement;
    myCanvas.width = targetWidth;
    myCanvas.height = targetHeight;
    let ctx = myCanvas.getContext('2d');
    ctx?.clearRect(0, 0, targetWidth, targetHeight);

    const scale = Math.max(targetWidth / width, targetHeight / height);
    const xOffset = -(width * scale - targetWidth) / 2;
    const yOffset = -(height * scale - targetHeight) / 2;
    ctx?.drawImage(img, x, y, width, height,
        xOffset, yOffset, width * scale, height * scale);
    return {
        data: myCanvas.toDataURL("image/jpeg", 0.8),
        width: targetWidth,
        height: targetHeight,
    };
}

export class ScreenshotPage extends Component<ScreenshotPageProps, ScreenshotPageState> {
    imageRef: React.Ref<HTMLImageElement>;
    constructor(props: ScreenshotPageProps) {
        super(props);
        this.imageRef = React.createRef();
        this.state = {
            opacity: 30,
            tags: [],
            selection: {x0: 0, x1: 0, y0: 0, y1: 0},
            selectionState: SelectionState.LOADING,
            originalScreenshot: '',
            sourceUrl: '',
            croppedSnapshot: null,
            loggedIn: false,
            username: "",
            email: "",
            logoutFn: () => {},
            userImageUrl: '',
            userLookupDone: false,
        }
    }

    updateTags = async () => {
        await setLoginState(this);
        axios({url: '/get_tags'})
            .then(response => {
                let tags = new Array<TagDetails>();
                response.data.tagDetails.forEach(t => {
                   tags.push(t); 
                });
                console.log('Tag details = ', response.data.tagDetails);
                this.setState({
                    tags: tags,
                })
            });
    }
    hasMouse(): boolean {
        return matchMedia('(pointer:fine)').matches;
    }

    startFromUpload(): void {
        if (this.props.screenshotDataUrl === undefined) {
            // This shouldn't be possible if we start from upload.
            return;
        }
        // We're on mobile
        if (this.imageRef === null) return;
        let img = this.imageRef.current;
        img.onload = () => {
            // Need to load the image first to figure out how large it is.
            const selection: Selection = {
                x0: img.width * 0.0,
                y0: img.height * 0.0,
                x1: img.width * 1.0,
                y1: img.height * 1.0,
            };
            this.cropImage(selection, (snapshot: Screenshot) => {
                this.setState({
                    selection: selection,
                    selectionState: SelectionState.CROPPED,
                    opacity: 50,
                    croppedSnapshot: snapshot,
                });
            });
        };
        this.setState({
            sourceUrl: 'uploaded',
            originalScreenshot: this.props.screenshotDataUrl,
        });
    }

    acceptMobileSelection = () => {
        this.setState({
            selectionState: SelectionState.CROPPED,
        });
    }
    componentDidMount(): void {
        if (this.props.screenshotDataUrl !== undefined) {
            this.startFromUpload();
        } else {
            window.addEventListener('message', (event) => {
                if (event.data.originalScreenshot === undefined) return;
                let responseCoords = event.data.selectionCoords;
                if (responseCoords === undefined || responseCoords.x === undefined) {
                    this.setState({
                        selectionState: SelectionState.STARTED,
                        sourceUrl: event.data.sourceUrl,
                        originalScreenshot: event.data.originalScreenshot,
                    });
                } else {
                        const selection: Selection = {
                            x0: responseCoords.x,
                            x1: responseCoords.x + responseCoords.width,
                            y0: responseCoords.y,
                            y1: responseCoords.y + responseCoords.height
                        };
                        this.setState({
                            selectionState: SelectionState.SELECTION_SET_BY_EXTENSION,
                            sourceUrl: event.data.sourceUrl,
                            originalScreenshot: event.data.originalScreenshot,
                            selection: selection,
                        });
                }
            }, true);

            window.parent.postMessage({getScreenshot: true}, "*");
        }

        if (this.props.tags === undefined) {
            this.updateTags().then(() => {  // also sets login state
                this.setState({
                    userLookupDone: true,
                });
            });
        } else {
            setLoginState(this).then(() => {
                this.setState({
                    userLookupDone: true,
                });
            });
        }
        amplitude.track('Started Screenshot');
    }

    componentDidUpdate(prevProps: Readonly<ScreenshotPageProps>, prevState: Readonly<ScreenshotPageState>, snapshot?: any): void {
        if (prevProps.screenshotDataUrl === undefined && this.props.screenshotDataUrl !== undefined) {
            this.startFromUpload();
        }
        if (!prevState.loggedIn && this.state.loggedIn) {
            this.updateTags();
        }
        if (this.state.selectionState === SelectionState.SELECTION_SET_BY_EXTENSION &&
            this.imageRef && this.imageRef.current) {
            this.imageRef.current.onload = () => {
                this.cropImage(this.state.selection, (snapshot: Screenshot) => {
                    this.setState({
                        selectionState: SelectionState.CROPPED,
                        opacity: 50,
                        croppedSnapshot: snapshot,
                    });
                });
            };
        }
    }

    login = () => {
        const closeUrl = window.location.origin + '/closeme';
        window.open('/welcome?login=true&next=' + encodeURI(closeUrl));
    }

    clearNewThreadBound = (thread: Thread) => {
        this.setState({
            selectionState: SelectionState.STARTED,
        });
    }
    updateSelectionBound = (thread: Thread) => {

    }

    refreshTags = () => {
        this.updateTags();
    }
    stylePositions = () => {
        const selection = this.state.selection;
        return {left: Math.min(selection.x0, selection.x1)  + "px",
            top: Math.min(selection.y0, selection.y1) + "px",
            width: Math.abs(selection.x1 - selection.x0) + "px",
            height: Math.abs(selection.y1 - selection.y0) + "px",
        };
    }
    topLeftCorner = () => {
        const selection = this.state.selection;
        return {left: (Math.min(selection.x0, selection.x1) - 12)  + "px",
                top: (Math.min(selection.y0, selection.y1) - 12) + "px" 
        };
    }
    editTopLeftCorner = () => {
        let selection = this.state.selection;
        selection.x0 = Math.max(selection.x0, selection.x1);
        selection.y0 = Math.max(selection.y0, selection.y1);
        this.setState({
            selection: selection,
            selectionState: SelectionState.FIRST_POINT,
        })
    }
    bottomRightCorner = () => {
        const selection = this.state.selection;
        return {left: (Math.max(selection.x0, selection.x1) - 14)  + "px",
                top: (Math.max(selection.y0, selection.y1) - 14) + "px" 
        };
    }
    editBottomRightCorner = () => {
        let selection = this.state.selection;
        selection.x0 = Math.min(selection.x0, selection.x1);
        selection.y0 = Math.min(selection.y0, selection.y1);
        this.setState({
            selection: selection,
            selectionState: SelectionState.FIRST_POINT,
        })
    }
    finishSelection(selection: Selection) {
        this.cropImage(selection, (snapshot: Screenshot) => {
            console.log('cropped image');
            this.setState({
                selectionState: this.hasMouse() ? SelectionState.CROPPED : SelectionState.MOBILE_UPLOAD_STARTED,
                opacity: 50,
                croppedSnapshot: snapshot,
            });
        });
    }
    setSelection = (event: React.MouseEvent | React.Touch) => {
        if (!this.state.loggedIn) return;
        let selection = this.state.selection;
        if (this.state.selectionState === SelectionState.STARTED) {
            selection.x0 = event.pageX - window.scrollX;
            selection.y0 = event.pageY - window.scrollY;
            selection.x1 = event.pageX - window.scrollX;
            selection.y1 = event.pageY - window.scrollY;
            this.setState({
                selection: selection,
                selectionState: SelectionState.FIRST_POINT,
            });
            console.log('set first point');
        } else if (this.state.selectionState === SelectionState.FIRST_POINT) {
            selection.x1 = event.pageX - window.scrollX;
            selection.y1 = event.pageY - window.scrollY;
            if (Math.abs(selection.x1 - selection.x0) + Math.abs(selection.y1 - selection.y0) < 10) return;
            this.setState({
                selection: selection,
                selectionState: SelectionState.FINISHED,
            });
            console.log('set second point');
            this.finishSelection(selection);
        }
    }
    setSelectionTouch = (event: React.TouchEvent) => {
        if (event.touches.length !== 0) return;
        if (this.state.selectionState !== SelectionState.FIRST_POINT) return;
        // console.log(event.touches[0]);
        // this.setSelection(event.touches[0]);
        // this.setState({
        //     selectionState: SelectionState.MOBILE_UPLOAD_STARTED,
        // });
        this.finishSelection(this.state.selection);
    }
    dragSelection = (event: React.MouseEvent | React.Touch) => {
        let selection = this.state.selection;
        if (this.state.selectionState === SelectionState.FIRST_POINT) {
            selection.x1 = event.pageX - window.scrollX;
            selection.y1 = event.pageY - window.scrollY;
            this.setState({
                selection: selection,
            });
        }
    }
    dragSelectionTouch = (event: React.TouchEvent) => {
        if (event.touches.length !== 1) return;
        this.dragSelection(event.touches[0]);
    }

    
    cropImage = (selection: Selection, cb: (snapshot: Screenshot) => void) => {
        if (this.imageRef === null) return;
        let originalImage = this.imageRef.current;
        // Only need zoomratio if it's a real screenshot.
        const zoomRatio = this.props.screenshotDataUrl === undefined ? window.devicePixelRatio : 
                originalImage.naturalWidth / originalImage.width;

        const x = Math.min(selection.x0, selection.x1) * zoomRatio;
        const y = Math.min(selection.y0, selection.y1) * zoomRatio;
        const width = Math.abs(selection.x0 - selection.x1) * zoomRatio;
        const height = Math.abs(selection.y0 - selection.y1) * zoomRatio;

        let img = new Image();
        img.onload = () => {
            // jpeg reduces size by 3x
            // 0.1 is same as png at 10x scale
            const thumbnailSize = 148;
            let screenshot: Screenshot = {
                primary: imageToScreenshotBundle(img, x, y, width, height, width, height),
                alternates: [
                    imageToScreenshotBundle(img, x, y, width, height, thumbnailSize, thumbnailSize),
                ]
            }
            cb(screenshot);
        };
        img.src = this.state.originalScreenshot;
    }
    threadPosition() {
        const left = Math.min(window.innerWidth - 96 * 4, Math.max(this.state.selection.x0, this.state.selection.x1));
        const top = Math.min(this.state.selection.y0, this.state.selection.y1);
        return {left: left, top: top, zIndex: 1};
    }
    cropButtonPosition() {
        const top = Math.min(this.state.selection.y0, this.state.selection.y1);
        return {left: 0, top: top, zIndex: 1};
    }

    
    showCropControls = () => {
        return (this.state.selectionState === SelectionState.CROPPED && this.hasMouse()) ||
            this.state.selectionState === SelectionState.MOBILE_UPLOAD_STARTED;
    }
    goBackToCropping = () => {
        this.setState({
            selectionState: SelectionState.MOBILE_UPLOAD_STARTED,
        })
    }
    closeScreenshotThread = () => {
        return this.goBackToCropping();
    }
    recentTagSort = (t0: TagDetails, t1: TagDetails) => {
        return new Date(t1.lastEditTime).getTime() - new Date(t0.lastEditTime).getTime();
    };
    cancel = () => {
        window.top?.close();
    }
    render() {
        if (this.props.screenshotDataUrl === undefined &&
            this.state.originalScreenshot === '') {
            return null;
        }
        let notifiedTags = this.state.tags.filter((t) => t.notifications > 0).sort(this.recentTagSort);
        let notNotifiedTags = this.state.tags.filter((t) => t.notifications === 0).sort(this.recentTagSort);
        return (
            <div className={"flex fixed top-0 left-0 w-screen h-screen " +
                    (this.props.screenshotDataUrl !== undefined ? "bg-white" : "")}>
                <img
                    ref={this.imageRef}
                    src={this.state.originalScreenshot}
                    className="fixed top-0 left-0"
                    alt="uploaded file from your device, now can be cropped"
                />
                <div style={displayIf(this.state.selectionState === SelectionState.STARTED)}
                    className="w-full z-20 pointer-events-none">
                    <div className="pointer-events-auto">
                        <Header
                            logoutFn={this.state.logoutFn}
                            username={this.state.username}
                            loggedIn={this.state.loggedIn}
                            initiallyShowLoginOverlay={false}
                            userImageUrl={this.state.userImageUrl}
                        />
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-grow pointer-events-none"></div>
                        <div className="flex hidden sm:block bg-transparent px-0 pb-0 mr-4 pointer-events-auto">
                            <div className="border-4 border-green-300 bg-white">
                                <div className="bg-white text-lg text-left px-4 p-2">
                                    {notifiedTags.length === 0 ? "No New Notifications" : "Visit Notified Tags"}
                                </div>
                                <div className="pl-2 pr-4 divide-y divide-y-gray-300 bg-white">
                                    {notifiedTags.slice(0, 4).map(t => {
                                    return (
                                        <div
                                            key={t.tagId}
                                            className="flex flex-row"
                                            >
                                            <div className="flex-grow">
                                                <TagChip
                                                    tagDetails={t}
                                                    bigClickBox={false}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                            <div className="border-4 border-gray-800 rounded-b-lg">
                                <div className="bg-white text-lg text-left px-4 pt-2">
                                    {notifiedTags.length > 0 ? "Other" : "Visit"} Recent Tags
                                </div>
                                <div className="pl-2 pr-4 divide-y divide-y-gray-300 bg-white rounded-b-lg">
                                    {notNotifiedTags.slice(0, 4).map(t => {
                                    return (
                                        <div
                                            key={t.tagId}
                                            className="flex flex-row"
                                            >
                                            <div className="flex-grow">
                                                <TagChip
                                                    tagDetails={t}
                                                    bigClickBox={false}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <button
                                    className="w-full mt-3 bg-red-700 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white hover:bg-red-800"
                                    onClick={this.cancel}>
                                    Cancel Screenshot
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex fixed top-0 left-0 w-full h-full bg-opacity-"
                    + this.state.opacity + " bg-gray-700 z-10"}
                    style={{cursor: this.showCropControls() ?
                        "auto" : "crosshair"}}
                    onMouseDown={this.setSelection}
                    onMouseUp={this.setSelection}
                    onMouseMove={this.dragSelection}
                    onTouchMove={this.dragSelectionTouch}
                    onTouchEnd={this.setSelectionTouch}
                    data-tip="Click and drag to select a screenshot"
                    >
                    {this.state.selectionState === SelectionState.STARTED ?
                        (<ReactTooltip />) : null}
                    <div 
                        style={{...displayIf(this.state.selectionState === SelectionState.MOBILE_UPLOAD_STARTED),
                                ...this.cropButtonPosition()
                            }}
                        className="flex fixed h-full w-full justify-center bg-opacity-50">
                        <button
                            className="border-0 bg-blue-600 text-white text-sm bg-opacity-50 shadow-md rounded p-2"
                            onClick={this.acceptMobileSelection}>
                            Upload Selection
                        </button>
                    </div>
                    <div
                        style={{...this.stylePositions(),
                            ...displayIf(!this.showCropControls())}}
                        className="fixed bg-opacity-0 border border-black">
                        
                    </div>
                    <img
                            className="fixed border border-black"
                            style={{...displayIf(this.showCropControls()),
                                    ...this.stylePositions(),
                                    ...{margin: '-2px'}}}
                            src={this.state.croppedSnapshot?.primary.data}
                            alt=""
                    />
                    <FiberManualRecord
                        style={{...this.topLeftCorner(),
                                ...displayIf(this.showCropControls())}}
                        className="fixed z-10"
                        onMouseDown={this.editTopLeftCorner}
                        onTouchStart={this.editTopLeftCorner}
                    />
                    <FiberManualRecord
                        style={{...this.bottomRightCorner(),
                                ...displayIf(this.showCropControls())}}
                        className="fixed z-10"
                        onMouseDown={this.editBottomRightCorner}
                        onTouchStart={this.editBottomRightCorner}
                    />
                    <canvas id='my-canvas-id' style={{display: 'none'}} />
                    <Transition.Root show={this.state.selectionState === SelectionState.CROPPED} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={this.closeScreenshotThread}>
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                                <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 lg:max-w-4xl md:max-w-2xl sm:max-w-xl sm:w-full">
                                    <Header
                                        logoutFn={this.state.logoutFn}
                                        username={this.state.username}
                                        loggedIn={this.state.loggedIn}
                                        initiallyShowLoginOverlay={false}
                                        userImageUrl={this.state.userImageUrl}
                                    />
                                    <div className="px-4 pt-5 pb-4 sm:p-6">
                                        <TuiNewThread
                                            redoCrop={this.goBackToCropping}
                                            allTags={this.state.tags}
                                            refreshTagsFn={this.refreshTags}
                                            screenshot={this.state.croppedSnapshot}
                                            sourceUrl={this.state.sourceUrl}
                                            isUpload={this.props.screenshotDataUrl !== undefined}
                                        />
                                    </div>
                                </Dialog.Panel>
                                </Transition.Child>
                            </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                    <Transition.Root show={this.state.userLookupDone && !this.state.loggedIn} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={() => {}}>
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                                <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                    <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Currently Logged Out
                                        </Dialog.Title>
                                        <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            You need to be logged in to take a screenshot.
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={this.login}
                                    >
                                        Log In
                                    </button>
                                    </div>
                                </Dialog.Panel>
                                </Transition.Child>
                            </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>
            </div>
            
        );
    }
}