import React, { Component } from 'react';
import { Header } from './tui_header';
import { LoginState } from './user';
import { Footer } from './footer'

type AboutProps = {};
type AboutState = LoginState & {

};

export class About extends Component<AboutProps, AboutState> {
    constructor(props: AboutProps) {
        super(props);
        this.state = {
            loggedIn: false,
            username: '',
            email: '',
            logoutFn: () => {},
            userImageUrl: '',
        };
    }

    render() {
        return (
            <div>
                <Header
                            loggedIn={this.state.loggedIn}
                            username={this.state.username}
                            logoutFn={this.state.logoutFn}
                            initiallyShowLoginOverlay={false}
                            userImageUrl={this.state.userImageUrl}
                      />
                <div className='flex flex-col mb-auto h-full text-left p-16 place-items-center'>
                    <div className='flex flex-col max-w-7xl px-16'>

                        <h1 className='text-left text-4xl my-4'>Why MarqLink</h1>
                        <p>In today's internet, information is often embedded in a wall of
                            promotional background material, or behind a temporary login url.
                            This was a daily frustration for me -- making it harder than it should be
                            to get back to things you want to remember or share.
                        </p>
                        <br />
                        <p>
                            So, I built MarqLink to solve this problem for myself. Even as I worked on
                            other things, I kept hitting the same need and coming back to add more to
                            MarqLink. Using this tool, I know that I can easily get back to
                            something if I'm interrupted. And, for the things I want to share with others,
                            I can condense some research down to its core, most efficiently
                            shared format -- a list of screenshots with my comments and annotations.
                            It's also great for developing websites, as I can capture the piece I want to
                            improve, draw/annotate my improvements directly, and then collapse that link once done.
                        </p>
                        <br />
                        <p>
                            MarqLink solves my problems, and I hope it can help solve yours too.
                        </p>
                        <h1 className='text-left text-4xl my-4'>Security and Sharing</h1>
                        <p>
                            Many tools exist that let you publicly post the things you find. MarqLink
                            is not one of these tools. Everything you do is private by default, but you
                            can share at the tag level or create a magic link that will let anyone who
                            has that link view your tag.
                        </p>
                        <br />
                        <p>
                            Marqlink screenshots are stored on Amazon's S3 with default encryption enabled,
                            so you don't have to worry about your content leaking.
                        </p>
                        <br />
                        <p>
                            There is not currently a way to add encryption on top of MarqLink, e.g. using your
                            own keys to encrypt and decrypt such that our servers never see your data. If this
                            is something you'd like, I'd love to hear more details via the "Feedback" button and
                            figure out how to make it happen.
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}