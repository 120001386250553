import { ZoomIn } from '@mui/icons-material';
import React, { Component } from 'react';
import { CanvasCoords, drawItem } from './markup';
import { DrawingType, DrawnItem, DrawnText } from './types';

type MarkedUpImageProps = {
    imageSource: string,
    markup: Array<DrawnItem>,
    onClick?: (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => void,
    onMouseUp?: (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => void,
    onMouseDown?: (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => void,
    onMouseMove?: (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => void,
    onSubmitText?: (text: string) => void,
    onCancelText?: () => void,
    showZoom?: boolean,
    cursor?: string,
    showTextEdit: boolean,
    locatorLength: number,
}

type MarkedUpImageState = {
    text: string,
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function toHexColor(x: number): string {
    let color: string = '#' + (x + 0x1000000).toString(16).substring(1).toUpperCase();
    return color;
}

export class MarkedUpImage extends Component<MarkedUpImageProps, MarkedUpImageState> {
    canvasRef: React.Ref<HTMLCanvasElement>;
    locatorRef: React.Ref<HTMLCanvasElement>;
    imageRef: React.Ref<HTMLImageElement>;
    constructor(props: MarkedUpImageProps) {
        super(props);
        this.canvasRef = React.createRef();
        this.locatorRef = React.createRef();
        this.imageRef = React.createRef();
        this.state = {
            text: "",
        }
    }

    componentDidMount() {
        this.createCanvas();
        window.addEventListener('resize', this.createCanvas);
        console.log('component mounted');
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.createCanvas);
    }

    componentDidUpdate(prevProps: MarkedUpImageProps, prevState: MarkedUpImageState) {
        this.createCanvas();
    }

    localizeCoords(event: React.MouseEvent | React.Touch): CanvasCoords {
        let canvas = this.canvasRef.current;
        let rect = canvas.getBoundingClientRect();
        const xScale = 1.0 / canvas.clientWidth;
        const yScale = 1.0 / canvas.clientHeight;
        return {
            x: (event.clientX - rect.left) * xScale,
            y: (event.clientY - rect.top) * yScale,
        }
    }

    setupCanvases(img: HTMLImageElement) {
        this.setupCanvas(img, false);
        this.setupCanvas(img, true);
    }

    setupCanvas(img: HTMLImageElement, locator: boolean) {
        let canvas = locator ? this.locatorRef.current : this.canvasRef.current;
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d', {willReadFrequently: true});
        if (ctx === null) return;
        if (locator) {
            ctx.fillStyle = '#FFFFFFFF';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        } else {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        let itemDrawLength = locator ? this.props.locatorLength : this.props.markup.length;
        for (let i = 0; i < itemDrawLength; i++) {
            drawItem(ctx, this.props.markup[i],
                locator ? toHexColor(i) : undefined);
        }
    }

    createCanvas = () => {
        if (this.imageRef === null) {
            return;
        }
        const img = this.imageRef.current;
        img.onload = () => {this.setupCanvases(img)}
        this.setupCanvases(img);
    }

    onClick = (event: React.MouseEvent | React.Touch) => {
        if (!this.props.onClick) return;
        const coords = this.localizeCoords(event);
        this.props.onClick(event, this.getDrawnItemIndex(coords), coords);
    }

    onMouseDown = (event: React.MouseEvent | React.Touch) => {
        if (!this.props.onMouseDown) return;
        const coords = this.localizeCoords(event);
        this.props.onMouseDown(event, this.getDrawnItemIndex(coords), coords);
    }

    onTouchStart = (event: React.TouchEvent) => {
        if (event.touches.length !== 1) return;
        this.onMouseDown(event.touches[0]);
    }

    onMouseUp = (event: React.MouseEvent | React.Touch) => {
        if (!this.props.onMouseUp) return;
        const coords = this.localizeCoords(event);
        this.props.onMouseUp(event, this.getDrawnItemIndex(coords), coords);
    }

    onTouchEnd = (event: React.TouchEvent) => {
        if (event.touches.length !== 0) return;
        if (event.changedTouches.length !== 1) return;
        return this.onMouseUp(event.changedTouches[0]);
    }

    onMouseMove = (event: React.MouseEvent | React.Touch) => {
        if (this.props.onMouseMove === undefined) return;
        const coords = this.localizeCoords(event);
        this.props.onMouseMove(event, this.getDrawnItemIndex(coords), coords);
    }

    onTouchMove = (event: React.TouchEvent) => {
        if (event.touches.length !== 1) return;
        console.log('touch moved');
        this.onMouseMove(event.touches[0]);
    }

    getDrawnItemIndex = (coords: CanvasCoords): number | null => {
        if (this.locatorRef === null) return null;
        let canvas = this.locatorRef.current;
        let ctx = canvas.getContext('2d', {willReadFrequently: true});
        if (ctx === null) return null;
        let pixel = ctx.getImageData(coords.x * canvas.width, coords.y * canvas.height, 1, 1).data;
        const index = pixel[0] * 256 + pixel[1] * 16 + pixel[2];
        if (index >= this.props.markup.length) return null;
        return index;
    }

    onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            text: event.target.value,
        });
    }
    onSubmitText = () => {
        if (this.props.onSubmitText === undefined) return;
        this.props.onSubmitText(this.state.text);
        this.setState({
            text: "",
        });
    }
    onCancelText = () => {
        if (this.props.onCancelText === undefined) return;
        this.props.onCancelText();
        this.setState({
            text: "",
        });
    }

    textEditCoords = () => {
        let coords = {
            x: 0,
            y: 0,
        }
        if (!this.canvasRef) return coords;
        let canvas = this.canvasRef.current;
        if (!canvas) return coords;
        if (this.props.markup.length === 0) return coords;
        let drawing = this.props.markup[this.props.markup.length - 1];
        if (drawing.drawingType !== DrawingType.Text) return coords;
        let textDrawing = drawing as DrawnText;
        coords.x = textDrawing.x0 * canvas.width;
        coords.y = textDrawing.y0 * canvas.height;
        return coords;
    }

    render() {
        const textEditCoords = this.textEditCoords();
        let fontSize = 24;
        let textWidth = fontSize * 5;
        let textHeight = fontSize * 2;
        if (this.props.markup.length > 0 &&
            this.props.markup[this.props.markup.length - 1].drawingType === DrawingType.Text) {
            let text = this.props.markup[this.props.markup.length - 1] as DrawnText;
            fontSize = text.size;
            if (this.canvasRef && this.canvasRef.current) {
                let canvas = this.canvasRef.current;
                fontSize = Math.floor(fontSize * canvas.height);
                textHeight = fontSize * 2 + 100;
                textWidth = Math.max(240, 1.2 * Math.abs(text.x1 - text.x0) * canvas.width);
            }
        }
        return (
            <div className="relative w-full">
                <img
                    ref={this.imageRef}
                    className={classNames("shadow-md rounded-bl-xl",
                        this.props.showZoom ? "" : "w-full")}
                    src={this.props.imageSource}
                    onClick={this.onClick}
                    alt="screenshot for a Marq Link"
                        />
                <canvas className="absolute top-0 left-0"
                    ref={this.canvasRef}
                    onMouseDown={this.onMouseDown}
                    onTouchStart={this.onTouchStart}
                    onMouseMove={this.onMouseMove}
                    onTouchMove={this.onTouchMove}
                    onMouseUp={this.onMouseUp}
                    onTouchEnd={this.onTouchEnd}
                    onClick={this.onClick}
                    style={{cursor: this.props.cursor}}
                >
                    
                </canvas>
                <canvas
                    ref={this.locatorRef}
                    style={{display: 'none'}} />
                {this.props.showZoom !== undefined && this.props.showZoom ? (
                    <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 z-10">
                        <ZoomIn
                            onClick={this.onClick}
                        />
                    </div>
                ) : null}
                <div className={classNames("absolute",
                    "border border-gray-300 rounded-lg shadow-sm overflow-hidden -ml-4 -mt-4",
                    this.props.showTextEdit ? "opacity-80" : "hidden")}
                    style={{
                        left: textEditCoords.x,
                        top: textEditCoords.y,
                    }}
                    >
                    <textarea
                        value={this.state.text}
                        onChange={this.onChangeText}
                        style={{
                            fontSize: fontSize, 
                            width: textWidth, height: textHeight}}
                        className="block p-4 pt-4 pb-12 leading-none focus:outline-0 border-none rounded-lg bg-transparent focus:ring-0 resize-none"
                        placeholder="Add text"
                        rows={3}
                    />
                    <div
                        className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                        <div className="flex flex-grow items-center space-x-5">
                        </div>
                        <div className="flex-shrink-0">
                            <button
                                type="submit"
                                onClick={this.onCancelText}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm mr-1 text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={this.onSubmitText}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}