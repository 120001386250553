import React, { Component, Fragment } from 'react';
import displayIf from './display_if';
import { TagChip } from './tag_chip';
import { Dialog, Transition } from '@headlessui/react'
import { TagDetails, TagId } from './types';
import { closeButton } from './close_button';

type AddTagProps = {
    userTags: Array<TagDetails>,
    alreadyAddedTagIds: Array<TagId>,
    postTag: (tagId: TagId) => void,
    submitNewTag: (name: string, description: string) => void,
    numToShow: number,
    showTagsByDefault: boolean,
}
type AddTagState = {
    addingTag: boolean,
    newTagDetails: TagDetails,
    search: string,
    showTags: boolean,
    name: string,
    description: string
}

export class AddTag extends Component<AddTagProps, AddTagState> {
    constructor(props: AddTagProps) {
        super(props);
        this.state = {
            addingTag: false,
            newTagDetails: {
                description: '',
                name: '',
                tagId: 0,
                editors: 1,
                lastEditTime: new Date(),
                threadCount: 0,
                screenshotUrl: '',
                notifications: 0,
                ordering: 'newest_first',
            },
            search: '',
            showTags: false,
            name: '',
            description: '',
        };
    }
    newTag = () => {
        this.setState({
            addingTag: true,
            name: this.state.search,
        })
    }
    stopAddingTag = () => {
        this.setState({
            addingTag: false,
            showTags: false,
        });
    }
    ignoreClick = (event: any) => {
        event.cancelBubble = true;
        if (event.stopPropagation) {
            event.stopPropagation();
        }
    }
    submitNewTag = () => {
        this.props.submitNewTag(this.state.name, this.state.description);
        this.stopAddingTag();
    }
    updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            search: event.target.value,
        });
    }
    onFocus = () => {
        this.setState({
            showTags: true,
        });
    }
    onBlur = () => {
        // this.setState({
        //     showTags: false,
        // });
    }
    postTag = (tagId: TagId) => {
        this.props.postTag(tagId);
        this.setState({
            showTags: false,
        });
    }
    onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.target.value,
        });
    }
    onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            description: event.target.value,
        });
    }
    render() {
        const lowerSearch = this.state.search.toLowerCase();
        const tags = this.props.userTags
                .filter(t => !this.props.alreadyAddedTagIds.includes(t.tagId))
                .filter(t => this.state.showTags || this.props.showTagsByDefault)
                .filter(t => {
                    return this.state.search === '' || t.name.toLowerCase().indexOf(lowerSearch) !== -1})
                .sort((t0, t1) => {
                    if (t1.lastEditTime === t0.lastEditTime) {
                        return 0;
                    } else if (t1.lastEditTime < t0.lastEditTime) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                .slice(0, this.props.numToShow)
                .map(t => {
            return (
                <TagChip
                    key={t.tagId}
                    tagDetails={t}
                    bigClickBox={true}
                    onClick={() => this.postTag(t.tagId)}
                />
            );
        });
        return (
            <div className="m-0 rounded-md border border-gray-300"
                onFocus={this.onFocus}
                onBlur={this.onBlur}>
                <div className="flex flex-row shadow-sm rounded-lg">
                    <input
                        type='text'
                        className='font-normal text-sm w-full text-left p-2 mx-0 rounded-lg'
                        placeholder='Tag this screenshot...'
                        value={this.state.search}
                        onChange={this.updateSearch}
                    />
                </div>
                <div className="px-1 divide-y divide-y-gray-300"
                >
                    {tags}
                    <div>
                        <button
                            style={displayIf(this.state.showTags || this.props.showTagsByDefault)}
                            className="flex my-1 w-full rounded bg-indigo-600 hover:bg-indigo-700 px-4 py-2 text-white font-medium text-sm"
                            onClick={this.newTag}
                        >
                            Create a New Tag
                        </button>
                    </div>
                </div>
                <Transition.Root show={this.state.addingTag} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={this.stopAddingTag}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                {closeButton(this.stopAddingTag)}
                                <form className="space-y-8 divide-y divide-gray-200">
                                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div className="space-y-6 sm:space-y-5">
                                        <div>
                                            <h3 className="text-lg my-2 leading-6 font-medium text-gray-900">Create Tag</h3>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Tags make your links easy to find and organize.</p>
                                        </div>
                                        <div className="space-y-6 sm:space-y-5">
                                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Tag Name
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                autoComplete="name"
                                                value={this.state.name}
                                                onChange={this.onChangeName}
                                                className="max-w-lg p-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                                                />
                                            </div>
                                            </div>

                                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Description
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <textarea
                                                rows={3}
                                                name="desc"
                                                id="desc"
                                                value={this.state.description}
                                                onChange={this.onChangeDescription}
                                                autoComplete="description"
                                                className="max-w-lg p-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </form>

                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    disabled={this.state.name.length === 0}
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                    onClick={this.submitNewTag}
                                >
                                    Create and apply tag
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={this.stopAddingTag}
                                >
                                    Cancel
                                </button>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        )
    }
}