import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';

type FooterProps = {};
type FooterState = {

};

type LinkParts = {
    name: string,
    href: string,
    icon?: any,
}

export class Footer extends Component<FooterProps, FooterState> {
    render() {
        let navigation: LinkParts[] = [
            // {
            //     name: "Product",
            //     href: "/product",
            // },
            {
                name: "About",
                href: "/about",
            },
            {
                name: "Privacy",
                href: "/privacy",
            },
            {
                name: "Terms of Use",
                href: "/terms",
            },
        ];
        let social: LinkParts[] = [];
        return (
            <footer className="bg-gray-900 mt-1">
                <CookieConsent>This website uses cookies to enhance the user experience,
                   such as handling logins. See our <a href='/privacy'>Privacy Policy</a> for more details.
               </CookieConsent>
                <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.map((item) => (
                    <div key={item.name} className="px-5 py-2">
                        <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                        </a>
                    </div>
                    ))}
                </nav>
                <div className="mt-8 flex justify-center space-x-6">
                    {social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                    ))}
                </div>
                <p className="mt-8 text-center text-base text-gray-400">Copyright &copy; 2022 Marq Link Technologies. All rights reserved.</p>
                </div>
            </footer>
        );
    }
};