import React, { Component } from 'react';
import displayIf from './display_if';

type CommentEditProps = {
    postReply: (comment: string) => boolean,
    cancel: () => void,
    startingContent: string,
    commentId: string,
    isNewThread: boolean,
    updateMode: boolean,
};

type CommentEditState = {
    commentText: string,
    default: boolean,
    editingTagIndex: number,
    focused: boolean,
};

export class CommentEdit extends Component<CommentEditProps, CommentEditState> {
    nameInput: any;
    constructor(props: CommentEditProps) {
        super(props);
        this.state = {
            commentText: this.props.startingContent,
            default: this.props.startingContent.length === 0,
            editingTagIndex: 0,
            focused: false,
        };

    }

    componentDidUpdate() {
        if (this.props.isNewThread) {
            // Autofocus doesn't work, because the component mounts before being shown.
            this.nameInput.focus();
        }

    }

    elementId = () => {
        return 'todo-fix-this-editable-id-' + this.props.commentId;
    }

    handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            commentText: event.target.value,
        });
    }

    handleOnFocus = (event: any) => {
        console.log('focusing');
        if (this.state.default) {
            this.setState({
                focused: true,
            });
        }
    }

    handleOnBlur = () => {
        if (this.state.commentText.length === 0) {
            this.setState({
                focused: false,
            });
        }
    }

    postReply = () => {
        if (!this.props.postReply(this.state.commentText)) return;
        if (this.props.updateMode) {
            this.setState({
                default: false,
                focused: false,
                editingTagIndex: 0,
            });
            
        } else {
            this.setState({
                commentText: '',
                default: true,
                focused: false,
                editingTagIndex: 0,
            });
        }
    }

    deleteComment = () => {
        this.props.postReply('');
        this.setState({
            // Shouldn't matter, as this should all be deleted.
            commentText: '',
            default: true,
            focused: false,
            editingTagIndex: 0,
        });
    }

    render() {
        return (
        <div className="flex items-start space-x-4">
            {/* <div className="flex-shrink-0">
                <img
                className="inline-block h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
                />
            </div> */}
            <div className="min-w-0 flex-1 relative">
                <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden">
                    <label htmlFor="comment" className="sr-only">
                    Add your comment
                    </label>
                    <textarea
                    rows={this.state.focused ? 3 : 1}
                    name="comment"
                    id="comment"
                    value={this.state.commentText}
                    onChange={this.handleOnChange}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    className="block text-sm w-full p-2 focus:outline-0 border-none rounded-lg focus:ring-0 resize-none sm:text-sm"
                    placeholder="Add your comment..."
                    />

                    {/* Spacer element to match the height of the toolbar */}
                    <div
                        className="py-2" aria-hidden="true">
                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                    <div className="py-px">
                        <div className="h-9" />
                    </div>
                    </div>
                </div>

                <div
                    className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                    <div className="flex flex-grow items-center space-x-5">
                    </div>
                    <div className="flex-shrink-0">
                        <button
                            type="submit"
                            onClick={this.postReply}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {this.props.updateMode ? 'Update' : 'Post'}
                        </button>
                    </div>
                    <div className="flex-shrink-0" style={displayIf(this.props.updateMode)}>
                        <button
                            type="submit"
                            onClick={this.deleteComment}
                            className="inline-flex ml-2 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
};