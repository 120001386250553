
export function maybePluralize(interval: number, length: string) {
  interval = Math.floor(interval);
  if (interval < 0) {
    interval = 0;
  }
  if (interval === 1) {
    return interval + ' ' + length;
  } else {
    return interval + ' ' + length + 's';
  }
}
export function timeSince(date: Date) {

    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  
    let interval = seconds / 31536000;
  
    if (interval > 1) {
      return maybePluralize(interval, 'year');
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return maybePluralize(interval, 'month');
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return maybePluralize(interval, 'day');
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return maybePluralize(interval, 'hour');
    }
    interval = seconds / 60;
    if (interval > 1) {
      return maybePluralize(interval, 'minute');
    }
    return maybePluralize(seconds, 'second');
  }