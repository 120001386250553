import { DrawingType, DrawnArrow, DrawnItem, DrawnLine, DrawnRectangle, DrawnText } from "./types";

export type CanvasCoords = {
    x: number,
    y: number,
}

function resize(vec: CanvasCoords, targetSize: number) {
    const length = Math.sqrt(
        vec.x * vec.x + vec.y * vec.y
    );
    return {
        x: vec.x / length * targetSize,
        y: vec.y / length * targetSize
    };
}

function inflate(ctx: CanvasRenderingContext2D, drawing: DrawnItem): DrawnItem {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;
    let drawingBigCoords = structuredClone(drawing) as DrawnLine | DrawnArrow | DrawnRectangle;
    drawingBigCoords.x0 = Math.floor(drawingBigCoords.x0 * width);
    drawingBigCoords.x1 = Math.floor(drawingBigCoords.x1 * width);

    drawingBigCoords.y0 = Math.floor(drawingBigCoords.y0 * height);
    drawingBigCoords.y1 = Math.floor(drawingBigCoords.y1 * height);
    return drawingBigCoords;
}

function inflateText(ctx: CanvasRenderingContext2D, drawing: DrawnItem): DrawnItem {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;
    let drawingBigCoords = structuredClone(drawing) as DrawnText;
    drawingBigCoords.x0 = Math.floor(drawingBigCoords.x0 * width);
    drawingBigCoords.x1 = Math.floor(drawingBigCoords.x1 * width);

    drawingBigCoords.y0 = Math.floor(drawingBigCoords.y0 * height);

    // font size can be multiplied by width or height; we try to always keep the image
    // in its original aspect ratio, so it doesn't matter which is used here,
    // so long as it's consistent.
    drawingBigCoords.size = Math.floor(drawingBigCoords.size * height);
    return drawingBigCoords;
}

export function drawItem(ctx: CanvasRenderingContext2D, drawing: DrawnItem, forceColor?: string) {
    switch(drawing.drawingType) {
        case DrawingType.Arrow:
        case DrawingType.Line:
        case DrawingType.Rectangle:
            drawing = inflate(ctx, drawing);
            break;
        case DrawingType.Text:
            drawing = inflateText(ctx, drawing);
            break;
        default:
            break;
    }
    if (forceColor === undefined) {
        ctx.strokeStyle = drawing.color;
        ctx.fillStyle = drawing.color;
        ctx.lineWidth = drawing.lineWidth;
    } else {
        ctx.strokeStyle = forceColor;
        ctx.fillStyle = forceColor;
        ctx.lineWidth = drawing.lineWidth * 3;
    }
    switch(drawing.drawingType) {
        case DrawingType.Arrow:
            let arrow = drawing as DrawnLine;
            let unitVector: CanvasCoords = resize({
                x: arrow.x1 - arrow.x0,
                y: arrow.y1 - arrow.y0
            }, 10);
            let intersectPoint: CanvasCoords = {
                x: arrow.x1 - unitVector.x,
                y: arrow.y1 - unitVector.y
            }
            ctx.beginPath();
            ctx.moveTo(arrow.x1, arrow.y1);
            ctx.lineTo(intersectPoint.x + unitVector.y, intersectPoint.y - unitVector.x);
            ctx.moveTo(arrow.x1, arrow.y1);
            ctx.lineTo(intersectPoint.x - unitVector.y, intersectPoint.y + unitVector.x);
            ctx.moveTo(arrow.x0, arrow.y0);
            ctx.lineTo(arrow.x1, arrow.y1);
            ctx.stroke();
            break;
        case DrawingType.Line:
            let line = drawing as DrawnLine;
            ctx.beginPath();
            ctx.moveTo(line.x0, line.y0);
            ctx.lineTo(line.x1, line.y1);
            ctx.stroke();
            break;
        case DrawingType.Rectangle:
            let rect = drawing as DrawnLine;
            ctx.beginPath();
            ctx.rect(Math.min(rect.x0, rect.x1), Math.min(rect.y0, rect.y1),
                Math.abs(rect.x0 - rect.x1), Math.abs(rect.y0 - rect.y1));
            ctx.stroke();
            break;
        case DrawingType.Text:
            let text = drawing as DrawnText;
            if (text.editable) break;
            ctx.font = text.size + "px " + text.font;
            ctx.textBaseline = "top";
            const writeAreaWidth = text.x1 - text.x0;
            let words = text.text.split(" ");
            let lines = Array<string>();
            let currentLine = Array<string>();
            for (let wi = 0; wi < words.length; ) {
                let possibleLine = currentLine.concat([words[wi]]);
                if (currentLine.length === 0 || ctx.measureText(possibleLine.join(" ")).width < writeAreaWidth) {
                    currentLine = possibleLine;
                    wi++;
                } else {
                    lines.push(currentLine.join(" "));
                    currentLine = [];
                }
            }
            lines.push(currentLine.join(" "));
            if (text.bold || forceColor !== undefined) {
                ctx.font = "bold " + ctx.font;
            }
            for (let li = 0; li < lines.length; li++) {
                ctx.fillText(lines[li], text.x0, text.y0 + li * text.size * 1.1, text.x1 - text.x0);
            }
            if (text.showBorder) {
                ctx.beginPath();
                ctx.moveTo(text.x0, text.y0 + text.size * 2);
                ctx.lineTo(text.x0, text.y0);
                ctx.lineTo(text.x1, text.y0);
                ctx.lineTo(text.x1, text.y0 + text.size * 2);
                ctx.stroke();
            }
            break;
        default:
            break;
    }
}