import { configureForFullPage, configureForIframe } from './configure';  // REQUIRED TO SET SHARED VARS
import React from 'react';
import './App.css';
import { TagPage } from './tag_page';
import { ScreenshotPage } from './screenshot_page';
import { Welcome } from './tui_welcome';
import { Terms } from './terms';
import { Privacy } from './privacy';
import { setUserInLocalStorage } from './user';
import { Profile } from './profile';
import { TagId } from './types';
import * as amplitude from '@amplitude/analytics-browser';
import { ExtensionInstalled } from './extension_installed';
import { About } from './about';

function App() {
  //axios.defaults.baseURL = process.env.DOMAIN;
  console.log('env is ', process.env);
  const url = new URL(window.location.href);
  console.log('URL is ', url);
  console.log('pathname is ', url.pathname);
  const showLogin = url.searchParams.has("login");
  if (url.pathname === '/closeme') {
    setUserInLocalStorage().then(() => {
      window.close();
    });
  }
  amplitude.init('c5066b56fd4ba79f3bce2709e5a3de39');
  if (url.pathname === '/screenshot') {
    configureForIframe();
    return (
      <div className="App">
        <ScreenshotPage
        />
      </div>
    );
  }
  configureForFullPage();
  if (url.pathname === '/welcome') {
    return (
      <div className="App">
        <Welcome
          showLogin={showLogin}
          signupToken={""}
        />
      </div>
    );
  }
  if (url.pathname === '/extension_installed') {
    return (
      <div className="App">
        <ExtensionInstalled
        />
      </div>
    );
  }
  if (url.pathname === '/signup' && url.searchParams.has("token")) {
    return (
      <div className="App">
        <Welcome
          showLogin={true}
          signupToken={url.searchParams.get("token")}
        />
      </div>
    );
  }
  if (url.pathname === '/terms') {
    return (
      <div className="App">
        <Terms
        />
      </div>
    );
  }
  if (url.pathname === '/privacy') {
    return (
      <div className="App">
        <Privacy
        />
      </div>
    );
  }
  if (url.pathname === '/about') {
    return (
      <div className="App">
        <About
        />
      </div>
    );
  }
  if (url.pathname === '/profile') {
    return (
      <div className="App">
        <Profile
        />
      </div>
    );
  }
  let tagName = url.searchParams.get("q");
  if (tagName === null) {
    tagName = '';
  }
  let tagParam = url.searchParams.get("tag");
  let tagId: null | TagId = null;
  if (tagParam !== null) {
    tagId = +tagParam;
  }
  const loggedOutKey = url.searchParams.get("key");
  return (
    <div className="App bg-gray-200">
      <TagPage
        tagName={tagName}
        tagId={tagId}
        loggedOutKey={loggedOutKey}
        notifications={(url.pathname === '/notified_tags')}
      />
    </div>
  );
}
export default App;
