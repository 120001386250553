import axios from 'axios';

export function isDevelopment() {
    return process.env.NODE_ENV === 'development';
}
export function configureForFullPage() {
    console.log('process node = ', process.env.NODE_ENV);
    axios.defaults.baseURL = isDevelopment() ? 'http://localhost:8009/api' :
            'https://api.marq.link/api';
    console.log('base url is ', axios.defaults.baseURL);
    axios.defaults.withCredentials = true;

}
export function configureForIframe() {
    configureForFullPage();
    axios.defaults.params = {};
    axios.defaults.params['email_jwt'] = localStorage.getItem('JWT_EMAIL_KEY');
}

export function buildApiUrl(suffix: string) {
    const email_jwt = localStorage.getItem('JWT_EMAIL_KEY');
    if (isDevelopment()) {
        return 'http://localhost:8009/api' + suffix + '&email_jwt=' + email_jwt;
    } else {
        return 'https://api.marq.link/api' + suffix + '&email_jwt=' + email_jwt;
    }
}