import React, { Component } from 'react';
import { ThreadBox } from './thread';
import { Thread, TagDetails, lastEditTime, PageThread, DrawnItem, UserDetails, TagId, ThreadId } from './types';
import { LoginState, setLoginState } from './user';
import axios from 'axios';
import { GetExtensionGuide } from './get_extension_guide';
import displayIf from './display_if';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Footer } from './footer';
import { BrowserView } from 'react-device-detect';
import { ScreenshotPage } from './screenshot_page';
import { Header } from './tui_header';
import { TuiTagHeader } from './tui_tag_header';
import { ListTags } from './list_tags';
import ReactTooltip from 'react-tooltip';
import { ScreenshotMarkup } from './screenshot_markup';
import { MarkedUpImage } from './marked_up_image';
import { Paginator } from './paginator';
import { classNames } from './tailwind';
import * as amplitude from '@amplitude/analytics-browser';
import { Login } from './login';

type TagPageProps = {
    tagName: string,
    tagId: TagId | null,
    loggedOutKey: string | null,
    notifications: boolean,
}

type TagPageState = LoginState & {
    // Tags used to filter the current page.
    tags: Array<string>,
    // All tags the user has access to. Might be truncated to most recent N.
    tagDetails: Array<TagDetails>,
    searchText: string,
    pageThreads: Array<PageThread>,
    imageOverlay: string,
    threadIdForSelectedImage: number,
    newUploadDataUrl?: string,
    hasExtension: boolean,
    notificationTime: number | null,
    // Notifications to show at load time; unaffected by refresh.
    pageLoadNotificationTime: number | null,
    refreshIntervalId: NodeJS.Timeout | null,
    currentPage: number,
    linkOrdering: string,
    showLogin: boolean,
}

export class TagPage extends Component<TagPageProps, TagPageState> {
    searchTextUpdateBound: (event: any) => void;
    updateThreadBound: (thread: Thread) => void;
    constructor(props: TagPageProps) {
        super(props);

        this.state = {
            tags: [],
            tagDetails: [],
            searchText: this.props.tagName === undefined ? '' : this.props.tagName,
            pageThreads: [],
            loggedIn: true,
            username: '',
            email: '',
            imageOverlay: '',
            threadIdForSelectedImage: -1,
            logoutFn: () => {},
            hasExtension: false,
            notificationTime: null,
            pageLoadNotificationTime: null,
            refreshIntervalId: null,
            currentPage: 0,
            userImageUrl: '',
            linkOrdering: 'newest_first',
            showLogin: false,
        }
        this.searchTextUpdateBound = this.searchTextUpdate.bind(this);
        this.updateThreadBound = this.updateThread.bind(this);
    }

    componentDidMount() {
        this.initialize();
        window.addEventListener("hashchange", () => {
            this.updatePagination();
        });
        amplitude.track('Tag Page');
    }

    updatePagination = () => {
        const hash = window.location.hash.substring(1);
        hash.split('&').forEach(element => {
            let parts = element.split('=');
            if (parts.length !== 2) return;
            if (parts[0] === 'page') {
                this.setState({
                    currentPage: +parts[1],
                });
                return;
            }
        });
    }

    componentWillUnmount(): void {
        if (this.state.refreshIntervalId === null) return;
        clearInterval(this.state.refreshIntervalId);
    }

    updateTags = async () => {
        await setLoginState(this);
        axios({url: '/get_tags' +
                (this.props.loggedOutKey !== null ? ('?key=' + this.props.loggedOutKey) : '')})
            .then(response => {
                let tags = new Array<TagDetails>();
                response.data.tagDetails.forEach(t => {
                    t.lastEditTime = new Date(t.lastEditTime);
                    tags.push(t);
                });
                tags.sort((a: TagDetails, b: TagDetails) => {return b.lastEditTime.getTime() - a.lastEditTime.getTime()});
                console.log('Tag details = ', response.data.tagDetails);
                this.setState({
                    tags: response.data.tags,
                    tagDetails: tags,
                })
            });
    }

    async initialize() {
        this.updateTags();
        window.addEventListener('visibilitychange', (event) => {
            if (document.visibilityState === 'visible') {
                this.updateTags();
                this.maybeRefresh();
                this.startRefreshLoop();
            } else {
                if (this.state.refreshIntervalId !== null) {
                    clearInterval(this.state.refreshIntervalId);
                    this.setState({
                        refreshIntervalId: null,
                    })
                }
            }
        });
        if (this.props.tagId !== null) {
            this.searchAsync(this.props.tagId);
            this.startRefreshLoop();
        }
    }

    startRefreshLoop() {
        if (this.props.tagId === undefined) return;
        if (this.state.refreshIntervalId !== null) return;
        const intervalId = setInterval(
            () => {
                this.maybeRefresh();
            }, 10000);
        this.setState({
            refreshIntervalId: intervalId,
        });
    }

    maybeRefresh() {
        if (this.props.tagId === undefined) return;
        axios.get('/get_notification_stats', {
            params: {
                tag_id: this.props.tagId,
            }
          }).then((response) => {
            let lastNotificationTime = response.data.last_notification_time_for_tag;
            if (lastNotificationTime === 0) return;
            let pageUpdatedTo = this.state.notificationTime ?? 0;
            if (lastNotificationTime > pageUpdatedTo) {
                this.searchAsync(this.props.tagId);
            }
        });
    }

    componentDidUpdate() {
        if (!this.props.loggedOutKey && !this.state.loggedIn) {
            window.location.replace("/welcome");
            return;
        }
    }

    searchTextUpdate(searchValue: string) {
        this.setState({searchText: searchValue});
    }

    search = (searchText: string) => {
        const url = new URL(window.location.href);
        window.location.href = url.pathname + '?q=' + encodeURIComponent(searchText);
    }

    hasExtensionCb = (hasExtension: boolean) => {
        console.log('Tag page has extension: ', hasExtension);
        this.setState({
            hasExtension: hasExtension,
        });
    }

    async searchAsync(tag: TagId | null) {
        if (tag === null) return;
        const request = {url: "/get_threads_by_tag?tag_id=" + tag +
            (this.props.loggedOutKey !== null ? ('&key=' + this.props.loggedOutKey) : '') +
            (this.props.notifications ? ('&notifications=1') : '')};
        let response = await axios(request);
        this.setState({
            pageThreads: response.data.page_threads,
            notificationTime: response.data.earliest_notification_time,
            linkOrdering: response.data.ordering,
        });
        if (this.state.pageLoadNotificationTime === null) {
            this.setState({
                pageLoadNotificationTime: response.data.earliest_notification_time,
            });
        }
    }

    updateThread(thread: Thread) {
        let threads = this.state.pageThreads;
        for (let i = 0; i < threads.length; i++) {
            if (threads[i].thread.threadId === thread.threadId) {
                threads[i].thread = thread;
            }
        }
        this.setState({pageThreads: threads});
    }

    deleteThread = (thread: Thread) => {
        let payload = {
            thread_id: thread.threadId,
        };
        axios({
            url: '/delete_thread',
            method: 'post',
            data: JSON.stringify(payload),
            //    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'} });
        }).then(() => {
            this.searchAsync(this.props.tagId);
        });
    }

    filterTag = (tag: string, search: string) => {
        if (search.length === 0) {
            // Empty matches all.
            return true;
        }
        let lowerTag = tag.toLowerCase();
        let lowerSearch = search.toLowerCase();
        return lowerTag.startsWith(lowerSearch);
    }
    
    refreshTags = () => {
        this.updateTags();
    }

    uploadNewScreenshot = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        if (!file) return;
        if (!/^image\//i.test(file.type)) return;
        let reader = new FileReader();
        let tagPage = this;
        reader.onloadend = function() {
            const dataUrl: string | ArrayBuffer = reader.result;
            if (typeof(dataUrl) === 'string') {
                tagPage.setState({
                    newUploadDataUrl: dataUrl,
                });
            }
        }
        reader.readAsDataURL(file);
    }

    pageSize() {
        // TODO: extract from hash
        return 10;
    }

    showLogin = () => {
        this.setState({showLogin: true});
    }
    stopShowingLogin = () => {
        this.setState({showLogin: false});
    }

    render() {
        const paginatedThreads = this.state.pageThreads.sort((t0: PageThread, t1: PageThread) => {
            // Most recent first.
            if (this.state.linkOrdering === 'newest_first') {
                return lastEditTime(t1.thread) - lastEditTime(t0.thread);
            } else {
                return lastEditTime(t0.thread) - lastEditTime(t1.thread);
            }
        }).slice(this.state.currentPage * this.pageSize(),
                (this.state.currentPage + 1) * this.pageSize());
        const threads = paginatedThreads.map((pageThread) => {
            return (<TagRow key={pageThread.thread.threadId}
                        pageThread={pageThread}
                        updateThreadFn={this.updateThreadBound}
                        deleteThreadFn={this.deleteThread}
                        refreshTagsFn={this.refreshTags}
                        allTags={this.state.tagDetails}
                        author={pageThread.thread.comments.length > 0 ? pageThread.thread.comments[0].author : ''}
                        user={{
                            username: this.state.username,
                            userImageUrl: '',
                            email: this.state.email,
                        }}
                        notificationTime={this.state.pageLoadNotificationTime}
                        showLogin={this.showLogin}
                        loggedIn={this.state.loggedIn}
                    />
                    );
        });
        let threadUsers = new Set<string>();
        let threadIds = new Set<ThreadId>();
        this.state.pageThreads.forEach((pageThread: PageThread) => {
            pageThread.thread.users.forEach((user: string) => {
                if (user !== this.state.email) {
                    threadUsers.add(user);
                }
            });
            threadIds.add(pageThread.thread.threadId);
        });
        const matchingTagDetails = this.state.tagDetails.filter(t => t.tagId === this.props.tagId);
        if (this.state.newUploadDataUrl) {
            return (
                <ScreenshotPage
                    screenshotDataUrl={this.state.newUploadDataUrl}
                    tags={this.state.tagDetails}
                />
            );
        }
        return (
            <div className="px-0 mx-auto flex max-w-screen-lg flex-col content-center bg-gray-200">
                
                <Header
                    logoutFn={this.state.logoutFn}
                    hasExtensionCb={this.hasExtensionCb}
                    loggedIn={this.state.loggedIn}
                    username={this.state.username}
                    initiallyShowLoginOverlay={false}
                    userImageUrl={this.state.userImageUrl}
                    uploadNewScreenshot={this.uploadNewScreenshot}
                 />
                 <Login
                     stopLoggingIn={this.stopShowingLogin}
                     showLoginOverlay={this.state.showLogin}
                     signupToken={""}
                 />
                {matchingTagDetails.length === 1 ?
                    <TuiTagHeader
                        tagDetails={matchingTagDetails[0]}
                        refreshTags={this.refreshTags}
                    />
                    : null
                }
                <div className="w-full m-0">
                    {this.props.tagId === null ? (
                        <ListTags
                            tagDetails={this.state.tagDetails}
                            notifications={this.props.notifications}
                        />
                    ) : (
                        <div>
                        {threads}
                        {Paginator(this.state.pageThreads.length, this.state.currentPage, this.pageSize())}
                        </div>
                    )}
                    <BrowserView>
                        {this.state.hasExtension ? null : (
                        <GetExtensionGuide />
                        )}
                    </BrowserView>
                </div>
                <Footer />
            </div>
        );
    }
};

type TagRowProps = {
    pageThread: PageThread,
    updateThreadFn: (thread: Thread) => void,
    deleteThreadFn: (thread: Thread) => void,
    refreshTagsFn: () => void,
    allTags: Array<TagDetails>,
    author: string,
    user: UserDetails,
    notificationTime: number | null,
    showLogin: () => void,
    loggedIn: boolean,
};
type TagRowState = {
    drawings: Array<DrawnItem>,
    showSelectedImage: boolean,
    screenshotData: string,
    hmax: string,
};
class TagRow extends Component<TagRowProps, TagRowState> {
    constructor(props: TagRowProps) {
        super(props);
        this.state = {
            drawings: this.props.pageThread.markup,
            showSelectedImage: false,
            screenshotData: '',
            hmax: '',
        }
    }

    // archived(props: TagRowProps) {
    //     return 
    // }

    // componentDidUpdate(prevProps: Readonly<TagRowProps>, prevState: Readonly<TagRowState>, snapshot?: any): void {
    //     if (prevProps.pageThread.thread.archived !== this.props.pageThread.thread.archived) {
    //         this.setState({hmax: "h-96"});
    //     }
    // }

    async sendThreadUpdateToServer(thread: Thread) {
        if (!this.props.loggedIn) return;
        await axios({
            url: '/update_thread',
            method: "POST",
            data: JSON.stringify({
                thread_id: thread.threadId,
                thread: thread,
            }),
        });
    }

    selectImage = () => {
        this.setState({
            showSelectedImage: true,
        });
    }

    unSelectImage = () => {
        this.setState({
            showSelectedImage: false,
        });
    }

    archiveThread = () => {
        this.setState({
            hmax: 'h-96'
        });
        setTimeout(this.executeArchive, 100);
    }

    restoreArchiveThread = () => {
        let thread = this.props.pageThread.thread;
        thread.archived = false;
        this.sendThreadUpdateToServer(thread).then(() => {
            this.props.updateThreadFn(thread);
            setTimeout(this.restoreFullHeight, 500);
        });
    }

    executeArchive = () => {
        let thread = this.props.pageThread.thread;
        thread.archived = true;
        this.sendThreadUpdateToServer(thread).then(() => {
            this.props.updateThreadFn(thread);
        });

    }

    restoreFullHeight = () => {
        this.setState({
            hmax: ''
        });
    }

    updateDrawings = (drawings: Array<DrawnItem>) => {
        let thisUserDrawings = drawings.filter((d) => {return d.user.email === this.props.user.email});
        const threadId = this.props.pageThread.thread.threadId;
        axios({
            url: '/update_markup',
            method: "POST",
            data: JSON.stringify({
                thread_id: threadId,
                markup: thisUserDrawings,
            }),
        }).then(() => {
            let otherUserDrawings = drawings.filter((d) => {return d.user.email !== this.props.user.email});
            this.setState({
                drawings: otherUserDrawings.concat(thisUserDrawings),
                showSelectedImage: false,
            });
        });
    }

    render() {
        let hostname = "Uploaded from device";
        if (this.props.pageThread.url.startsWith("http")) {
            hostname = new URL(this.props.pageThread.url).hostname;
        }
        const archived = this.props.pageThread.thread.archived !== undefined &&
                this.props.pageThread.thread.archived;
        return (
            <div
                className="lg:grid lg:grid-cols-5 lg:grid-flow-row-dense bg-white mx-0 mt-1 rounded-xl
                border border-gray-300 shadow-sm">
                <div className="flex flex-row lg:col-span-5 lg:col-start-1 justify-center
                    bg-gray-300 text-black text-sm font-medium p-2 rounded-t-xl">
                    <div className="flex flex-grow justify-center">
                        {this.props.pageThread.url.startsWith("http") ?
                        (
                            <div>
                                <div className="md:block hiddenp-2">{this.props.author}'s MarqLink from </div>
                                <a target="_blank" rel="noopener noreferrer"
                                    className="underline px-2 text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                    href={this.props.pageThread.url}>
                                        {hostname}
                                </a>
                            </div>
                        ) :
                        (
                            <div className="md:block hiddenp-2">{this.props.author}'s MarqLink</div>
                        )
                        }
                    </div>
                    <div className="flex flex-shrink">
                        <div className={classNames(
                            archived ? "sm:block" : "",
                            "hidden bg-gray-200 rounded text-sm text-gray-700 text-center p-2 mx-1"
                        )}>Archived</div>
                        <button
                            data-tip="Collapse this link"
                            onClick={this.archiveThread}
                            style={displayIf(!archived)}>
                            <UnfoldLess />
                        </button>
                        <ReactTooltip />
                        <button
                            data-tip="Restore this link"
                            onClick={this.restoreArchiveThread}
                            style={displayIf(archived)}>
                            <UnfoldMore />
                        </button>
                    </div>
                </div>
                <div
                    className={classNames(
                        "flex flex-col w-full lg:col-span-3 lg:col-start-1 justify-start group",
                        (archived ? "h-0" : this.state.hmax),
                        "overflow-hidden transition-all duration-500 justify-center"
                    )}>
                    <div className="flex relative my-auto justify-center">
                        <MarkedUpImage
                            imageSource={this.props.pageThread.screenshotUrl}
                            markup={this.state.drawings}
                            onClick={this.selectImage}
                            showTextEdit={false}
                            locatorLength={0}
                            author={this.props.author}
                        />
                    </div>
                </div>
                <div
                    className={classNames("lg:col-span-2 lg:col-start-4 mr-1",
                    (archived ? "h-0" : this.state.hmax),
                    "overflow-y-auto overflow-x-hidden transition-all duration-500"
                )}>
                    <ThreadBox
                        thread={this.props.pageThread.thread}
                        startFocused={false}
                        isNewThread={false}
                        updateThreadFn={this.props.updateThreadFn}
                        deleteThreadFn={this.props.deleteThreadFn}
                        refreshTagsFn={this.props.refreshTagsFn}
                        updateSelectionFn={() => {}}
                        selected={false}
                        tagPage={true}
                        allTags={this.props.allTags}
                        notificationTime={this.props.notificationTime}
                        showLogin={this.props.showLogin}
                    />
                </div>
                <ScreenshotMarkup
                    showEditOverlay={this.state.showSelectedImage}
                    stopShowingEditOverlay={this.unSelectImage}
                    updateDrawings={this.updateDrawings}
                    imageSource={this.props.pageThread.screenshotUrl}
                    user={this.props.user}
                    showLogin={this.props.showLogin}
                    drawnItems={this.state.drawings}
                    author={this.props.author}
                />

            </div>
        );
    }
}