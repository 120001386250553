import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DrawingType, DrawnItem, DrawnLine, DrawnRectangle, DrawnText, UserDetails } from './types';
import { ArrowRightIcon, ChatIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { SquareOutlined } from '@mui/icons-material';
import { TwitterPicker } from 'react-color';
import { CanvasCoords } from './markup';
import { MarkedUpImage } from './marked_up_image';
import { classNames } from './tailwind';
import { closeButton } from './close_button';

type ScreenshotMarkupProps = {
    showEditOverlay: boolean,
    stopShowingEditOverlay: () => void,
    updateDrawings: (drawings: Array<DrawnItem>) => void,
    imageSource: string,
    user: UserDetails,
    drawnItems: Array<DrawnItem>,
    showLogin: () => void,
    author: string,
}

type ScreenshotMarkupState = {
    drawnItems: Array<DrawnItem>,
    drawMode: DrawingType,
    currentDrawing: DrawnItem | null,
    color: string,
}

type EditOption = {
    drawingType: DrawingType,
    jsx: JSX.Element,
    text: string,
}

export class ScreenshotMarkup extends Component<ScreenshotMarkupProps, ScreenshotMarkupState> {
    constructor(props: ScreenshotMarkupProps) {
        super(props);
        this.state = {
            drawMode: DrawingType.Arrow,
            drawnItems: this.props.drawnItems,
            currentDrawing: null,
            color: "#000000",
        }
    }

    onMouseDown = (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => {
        let currentDrawing = this.state.currentDrawing;
        switch(this.state.drawMode) {
            case DrawingType.Line:
            case DrawingType.Arrow:
                if (currentDrawing === null) {
                    let line: DrawnLine = {
                        color: this.state.color,
                        user: this.props.user,
                        drawingType: this.state.drawMode,
                        x0: coords.x,
                        y0: coords.y,
                        x1: coords.x,
                        y1: coords.y,
                        lineWidth: 2,
                    }
                    this.setState({
                        currentDrawing: line,
                    });
                }
                break;
            case DrawingType.Rectangle:
                if (currentDrawing === null) {
                    let rect: DrawnRectangle = {
                        color: this.state.color,
                        user: this.props.user,
                        drawingType: this.state.drawMode,
                        x0: coords.x,
                        y0: coords.y,
                        x1: coords.x,
                        y1: coords.y,
                        lineWidth: 2,
                    }
                    this.setState({
                        currentDrawing: rect,
                    });
                }
                break;
            case DrawingType.Text:
                if (currentDrawing === null) {
                    let text: DrawnText = {
                        color: this.state.color,
                        user: this.props.user,
                        drawingType: this.state.drawMode,
                        x0: coords.x,
                        y0: coords.y,
                        x1: coords.x,
                        lineWidth: 2,
                        font: "sans-serif",
                        size: .025,
                        text: "Add text",
                        bold: false,
                        editable: false,
                        showBorder: true,
                    }
                    this.setState({
                        currentDrawing: text,
                    });
                }
                break;
            case DrawingType.Edit:
                if (clickedItemIndex === null) return;
                let items = this.state.drawnItems;
                items.splice(clickedItemIndex, 1);
                this.setState({
                    drawnItems: items,
                    currentDrawing: null,
                });
                break;
            default:
                break;
        }
    }

    onMouseUp = (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => {
        let currentDrawing = this.state.currentDrawing;
        let drawnItems = this.state.drawnItems;
        switch(this.state.drawMode) {
            case DrawingType.Line:
            case DrawingType.Arrow:
                if (currentDrawing !== null) {
                    let line = currentDrawing as DrawnLine;
                    line.x1 = coords.x;
                    line.y1 = coords.y;
                    drawnItems.push(line);
                    this.setState({
                        drawnItems: drawnItems,
                        currentDrawing: null,
                    });
                }
                break;
            case DrawingType.Rectangle:
                if (currentDrawing !== null) {
                    let rect = currentDrawing as DrawnRectangle;
                    rect.x1 = coords.x;
                    rect.y1 = coords.y;
                    drawnItems.push(rect);
                    this.setState({
                        drawnItems: drawnItems,
                        currentDrawing: null,
                    });
                }
                break;
            case DrawingType.Text:
                if (currentDrawing !== null) {
                    let text = currentDrawing as DrawnText;
                    text.editable = true;
                    this.setState({
                        currentDrawing: text,
                    });
                }
                break;
            case DrawingType.Edit:
            default:
                break;
        }
    }

    onDrag = (event: React.MouseEvent | React.Touch, clickedItemIndex: number | null, coords: CanvasCoords) => {
        let currentDrawing = this.state.currentDrawing;
        switch(this.state.drawMode) {
            case DrawingType.Line:
            case DrawingType.Arrow:
                if (currentDrawing !== null) {
                    let line = currentDrawing as DrawnLine;
                    line.x1 = coords.x;
                    line.y1 = coords.y;
                    this.setState({
                        currentDrawing: line,
                    });
                }
                break;
            case DrawingType.Rectangle:
                if (currentDrawing !== null) {
                    let rect = currentDrawing as DrawnLine;
                    rect.x1 = coords.x;
                    rect.y1 = coords.y;
                    this.setState({
                        currentDrawing: rect,
                    });
                }
                break;
            case DrawingType.Text:
                if (currentDrawing !== null) {
                    let text = currentDrawing as DrawnText;
                    if (text.editable) break;
                    text.x1 = coords.x;
                    text.size = Math.max(0.01, Math.abs(text.y0 - coords.y));
                    this.setState({
                        currentDrawing: text,
                    });
                }
                break;
            case DrawingType.Edit:
                if (clickedItemIndex === null) {
                    this.setState({
                        currentDrawing: null,
                    });
                    return;
                }
                let selectedItem = structuredClone(this.state.drawnItems[clickedItemIndex]);
                selectedItem.lineWidth = 4;
                if (selectedItem.drawingType === DrawingType.Text) {
                    (selectedItem as DrawnText).bold = true;
                }
                this.setState({
                    currentDrawing: selectedItem,
                });
                break;
            default:
                break;
        }

    }

    onSubmitText = (text: string): void => {
        if (this.state.currentDrawing === null) return;
        if (this.state.currentDrawing.drawingType !== DrawingType.Text) return;
        let currentText = this.state.currentDrawing as DrawnText;
        currentText.text = text;
        currentText.editable = false;
        currentText.showBorder = false;
        let drawnItems = this.state.drawnItems;
        drawnItems.push(currentText);
        this.setState({
            drawnItems: drawnItems,
            currentDrawing: null,
        });
    }

    onCancelText = (): void => {
        this.setState({
            currentDrawing: null,
        });
    }

    changeDrawMode = (mode: DrawingType) => {
        this.setState({
            drawMode: mode,
            currentDrawing: null,
        });
    }

    handleColor = (color: any) => {
        this.setState({
            color: color.hex,
        });
    }

    saveMarkup = () => {
        this.props.updateDrawings(this.state.drawnItems);
    }

    render() {
        const editors: Array<EditOption> = [
            {
                drawingType: DrawingType.Arrow,
                jsx: (<ArrowRightIcon className="-ml-1 mr-2 h-5 w-5" />),
                text: "Add an Arrow"
            },
            {
                drawingType: DrawingType.Line,
                jsx: (<PencilIcon className="-ml-1 mr-2 h-5 w-5" />),
                text: "Add a Line"
            },
            {
                drawingType: DrawingType.Rectangle,
                jsx: (<SquareOutlined className="-ml-1 mr-2 h-5 w-5" />),
                text: "Add a Rectangle"
            },
            {
                drawingType: DrawingType.Text,
                jsx: (<ChatIcon className="-ml-1 mr-2 h-5 w-5" />),
                text: "Add text"
            },
            {
                drawingType: DrawingType.Edit,
                jsx: (<TrashIcon className="-ml-1 mr-2 h-5 w-5" />),
                text: "Delete a Shape"
            },
        ];
        return (
            <div>
                <Transition.Root show={this.props.showEditOverlay} as={Fragment}>
                    <Dialog as="div" className="relative z-10 max-h-full" onClose={this.props.stopShowingEditOverlay}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center max-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-full sm:w-full sm:p-6 sm:m-6">
                            <div className="max-h-screen flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                                {closeButton(this.props.stopShowingEditOverlay)}
                                <h2 className="m-2 text-center text-3xl font-bold text-gray-900">
                                    Annotate {this.props.author === '' ? 'your' : this.props.author + '\'s'} screenshot
                                </h2>
                                <span className="relative z-0 inline-flex shadow-sm rounded-md py-4">
                                    {
                                        editors.map((editor, index) => {
                                            return <button
                                                type="button"
                                                onClick={() => {this.changeDrawMode(editor.drawingType)}}
                                                className={classNames("relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium",
                                                    this.state.drawMode === editor.drawingType ? "bg-indigo-500 text-white"
                                                    : "hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700",
                                                    index === 0 ? "rounded-l-md" : "-ml-px",
                                                    index === editors.length - 1 ? "rounded-r-md" : ""
                                                )}>
                                                {editor.jsx}
                                                <span className="hidden sm:block">{editor.text}</span>
                                            </button>
                                        })
                                    }
                                </span>
                                <TwitterPicker
                                    onChange={this.handleColor}
                                    color={this.state.color}
                                    colors={['#000000', '#FFFFFF', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                                    width={"100%"}
                                />
                                <div className="flex-grow overflow-y-auto">
                                    <MarkedUpImage
                                        showTextEdit={this.state.drawMode === DrawingType.Text && this.state.currentDrawing !== null &&
                                                (this.state.currentDrawing as DrawnText).editable}
                                        onSubmitText={this.onSubmitText}
                                        onCancelText={this.onCancelText}
                                        onMouseDown={this.onMouseDown}
                                        onMouseUp={this.onMouseUp}
                                        onMouseMove={this.onDrag}
                                        imageSource={this.props.imageSource}
                                        cursor={this.state.drawMode === DrawingType.Edit ? "default" : "crosshair"}
                                        markup={this.state.drawnItems.concat(
                                            this.state.currentDrawing === null ? [] : [this.state.currentDrawing])}
                                        locatorLength={this.state.drawnItems.length}
                                    />
                                </div>
                                <div className="flex justify-evenly mt-4">
                                    <button
                                            type="button"
                                            onClick={this.props.stopShowingEditOverlay}
                                            className="relative inline-flex items-center px-12 py-2 rounded-md border border-gray-300 bg-red-500 text-white text-md font-medium hover:bg-red-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                        Cancel
                                    </button>
                                    { this.props.user.email === "" ? (
                                        <button
                                                type="button"
                                                onClick={this.props.showLogin}
                                                className="relative inline-flex items-center px-12 py-2 rounded-md border border-gray-300 bg-indigo-500 text-white text-md font-medium hover:bg-indigo-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                            >
                                            Log in to save annotations
                                        </button>
                                    ) : (
                                        <button
                                                type="button"
                                                onClick={this.saveMarkup}
                                                className="relative inline-flex items-center px-12 py-2 rounded-md border border-gray-300 bg-indigo-500 text-white text-md font-medium hover:bg-indigo-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                            >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        );
    }
}