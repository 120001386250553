import React, { Component } from 'react';
import displayIf from './display_if';

type GetExtensionGuideProps = {}

enum Stage {
    GetLink = 1,
    InstallLink,
    GrabText,
}
type GetExtensionGuideState = {
    stage: Stage,
}

export class GetExtensionGuide extends Component<GetExtensionGuideProps, GetExtensionGuideState> {
    constructor(props: GetExtensionGuideProps) {
        super(props);
        this.state = {
            stage: Stage.GetLink,
        }
    }

    downloadClick = () => {
        this.setState({
            stage: Stage.InstallLink,
        })
    }

    installClick = () => {
        this.setState({
            stage: Stage.GrabText,
        })
    }

    render() {
        return (
            <div className="flex flex-row bg-gray-200 m-1 p-1 rounded">
                <div
                    style={displayIf(this.state.stage === Stage.GetLink)}
                    className="flex flex-col w-3/12 p-1 justify-center">
                    To add another link, get
                    <br />
                    <a className="underline" target='_blank' href='https://chrome.google.com/webstore/detail/marqlink-extension/apomjoebmhkipmpoodjbhiibekmpjldd'
                        rel="noreferrer">
                        the extension.
                    </a>
                </div>
                <div
                    style={displayIf(this.state.stage === Stage.InstallLink)}
                    className="flex flex-col w-3/12 p-1 justify-center">
                    Now that you've downloaded the extension, unzip it. Then, install it by visiting
                    chrome://extensions
                    and selecting "Load unpacked" and pointing to the "marqlink-ext" folder in the unzipped
                    directory.
                    <br />
                    Now, you can click on the extension from the top-right of chrome on any page to make
                    comments and connect them to this or other tags.
                </div>
            </div>
        );
    }
}