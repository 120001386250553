import React, { Component, ReactFragment } from 'react';
import { Thread, TagDetails, TagId, Comment }  from './types';
import { CommentBox } from './comment';
import { LoginState, setLoginState } from './user';
import axios from 'axios';
import { CommentEdit } from './comment_edit';
import displayIf from './display_if';
import { AddTag } from './add_tag';
import { TagChip } from './tag_chip';
import { transition } from './transitions';
import { maybePluralize } from './dates';
import * as amplitude from '@amplitude/analytics-browser';

type ThreadProps = {
    thread: Thread,
    updateThreadFn: (thread: Thread) => void,
    deleteThreadFn: (thread: Thread) => void,
    updateSelectionFn: (thread: Thread) => void,
    refreshTagsFn: () => void,
    redoCrop?: () => void,
    startFocused: boolean,
    isNewThread: boolean,
    selected: boolean,
    tagPage: boolean,
    allTags: Array<TagDetails>,
    notificationTime: number | null,
    showLogin: () => void,
};
type ThreadState = LoginState & {
    focused: boolean,
    newCommentText: string,
    showDeleteThreadWarning: boolean,
};

function getTags(commentText: string): Array<string> {
    console.log('extracting tags from ', commentText);
    let tags = commentText.match(/#(\w+)/g);
    if (tags === null) {
        return [];
    }
    return tags;
}

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export class ThreadBox extends Component<ThreadProps, ThreadState> {
    handleChangeBound: (event: any) => void;
    focusBound: () => void;
    unFocusBound: () => void;
    loginBound: () => void;
    constructor(props: ThreadProps) {
        super(props);
        this.state = {
            focused: this.props.startFocused,
            newCommentText: '',
            loggedIn: true,
            username: '',
            email: '',
            userImageUrl: '',
            logoutFn: () => {},
            showDeleteThreadWarning: false,
        }
        this.handleChangeBound = this.handleChange.bind(this);
        this.focusBound = this.focus.bind(this);
        this.unFocusBound = this.unFocus.bind(this);
        this.loginBound = this.login.bind(this);
    }

    componentDidMount() {
        setLoginState(this);
    }

    focus() {
        this.setState({
            focused: true,
        });
        window.parent.postMessage({
            scrollToId: this.props.thread.anchor.parentDivId,
        }, "*");
        this.props.updateSelectionFn(this.props.thread);
    }

    unFocus() {
        this.setState({
            focused: false,
        });
        console.log('removed focus');
    }

    handleChange(event: any) {
        console.log('got change update from div box: ', event);
        this.setState({newCommentText: event.target.innerText});
        // TODO: show possibly matching tags
    }

    postReply = (commentText: string)  => {
        amplitude.track('Posted Comment');
        if (this.state.username === "") {
            this.props.showLogin();
            return false;
        }
        let thread = this.props.thread;
        thread.comments.push({
            author: this.state.username,
            text: commentText,
            editTime: new Date().getTime(),
            tags: getTags(commentText),
            reactions: [],
        });
        this.setState({
            focused: false,
            newCommentText: '',
        });
        
        // BUGGED: DOESNT WORK FOR NEW THREADS

        if (this.props.isNewThread) {
            this.props.updateThreadFn(thread);
        } else {
            this.sendThreadUpdateToServer(thread).then(() => {
                this.props.updateThreadFn(thread);
            });
        }
        return true;
    }

    updateThread = (commentIndex: number, comment: Comment) => {
        let thread = this.props.thread;
        if (comment.text === '' && comment.reactions.length === 0) {
            thread.comments.splice(commentIndex, 1);
        } else {
            thread.comments[commentIndex].text = comment.text;
            thread.comments[commentIndex].tags = [];
            thread.comments[commentIndex].reactions = comment.reactions;
        }
        this.sendThreadUpdateToServer(thread).then(() => {
            this.props.updateThreadFn(thread);
        });
    }

    async sendThreadUpdateToServer(thread: Thread) {
        await axios({
            url: '/update_thread',
            method: "POST",
            data: JSON.stringify({
                thread_id: thread.threadId,
                thread: thread,
            }),
        });
    }

    componentDidUpdate(prevProps: ThreadProps) {
        if (prevProps.selected && !this.props.selected) {
            // Tells the parent window to un-highlight.
            window.parent.postMessage(
                {unfocusMarkId: this.props.thread.anchor.parentDivId},
                "*"
            );
            this.unFocus();
        }
    }

    login() {
        if (window === null || window.location === null) return;
        if (inIframe()) {
            window.open("/welcome?login=true&next=" + encodeURIComponent(window.location.origin + '/closeme'), '_blank').focus();
        } else {
            window.location.replace("/welcome?login=true&next=" + encodeURIComponent(window.location.href));
        }
    }

    addTag = (tagId: TagId) => {
        console.log('adding tag: ', tagId);
        let thread = this.props.thread;
        axios({
            url: '/add_tag',
            method: "POST",
            data: JSON.stringify({
                thread_id: thread.threadId,
                tag_id: tagId,
            }),
        }).then(() => {
            amplitude.track('Add Tag');
            thread.tagIds.push(tagId);
            this.props.updateThreadFn(thread);
            if (!this.props.tagPage && window.top) {
                window.top.location.replace(window.location.origin + "/tag/?tag=" + tagId);
            }
        });
    }

    unTag(tagId: TagId) {
        if (this.props.thread.tagIds.length === 1) {
            this.setState({
                showDeleteThreadWarning: true,
            });
            return;
        }
        let thread = this.props.thread;
        axios({
            url: '/remove_tag',
            method: "POST",
            data: JSON.stringify({
                thread_id: thread.threadId,
                tag_id: tagId,
            }),
        }).then(() => {
            thread.tagIds = thread.tagIds.filter(t => t !== tagId);
            this.props.updateThreadFn(thread);
        });

    }

    submitNewTag = (name: string, description: string) => {
        axios({
            url: '/create_tag',
            method: "POST",
            data: JSON.stringify({
                name: name,
                description: description,
            }),
        }).then(response => {
            amplitude.track('Create Tag');
            this.props.refreshTagsFn();
            const newTagId = response.data.tagId;
            this.addTag(newTagId);
        });
    }

    cancelDelete = () => {
        this.setState({showDeleteThreadWarning: false});
    }

    deleteThread = () => {
        this.props.deleteThreadFn(this.props.thread);
    }

    render() {
        let comments: Array<ReactFragment> = [];
        for (let i = 0; i < this.props.thread.comments.length; i++) {
            comments.push(
                <CommentBox
                    key={i}
                    commentIndex={i}
                    threadId={this.props.thread.threadId.toString()}
                    comment={this.props.thread.comments[i]}
                    updateThreadFn={this.updateThread}
                    currentUsername={this.state.username}
                    notificationTime={this.props.notificationTime}
                />
            )
        }
        const tags = this.props.allTags.filter(t => this.props.thread.tagIds.includes(t.tagId)).map(t => {
            return <TagChip
                key={t.tagId}
                tagDetails={t}
                removeTag={() => this.unTag(t.tagId)}
                bigClickBox={false}
            />;
        });
        return (
            <div 
                className={"text-black font-bold py-2 pl-8 lg:pl-4 pr-4 bg-white m-px " +
                        (this.props.tagPage ? "w-full" : (
                            this.props.selected ? "w-11/12" : "w-5/6"))}>
                <div 
                    className="mb-2"
                    onClick={this.focusBound}>
                    <div
                        className="text-left"
                    >
                        Comments
                    </div>
                    {comments}
                </div>
                <CommentEdit
                    isNewThread={this.props.isNewThread}
                    postReply={this.postReply}
                    cancel={this.unFocusBound}
                    startingContent={''}
                    commentId={'new-' + this.props.thread.threadId}
                    updateMode={false}
                />
                <div className="lg:border lg:border-4 m-2 w-full h-px"></div>
                <div
                    style={displayIf(this.state.username !== "")}>
                    <div
                        className="text-left"
                    >
                        Tags
                    </div>
                    <div>
                        {tags}
                    </div>
                    <div style={displayIf(this.props.thread.threadId !== 0)}>
                        <AddTag
                            userTags={this.props.allTags}
                            alreadyAddedTagIds={this.props.thread.tagIds}
                            postTag={this.addTag}
                            submitNewTag={this.submitNewTag}
                            numToShow={this.props.tagPage ? 4 : 3}
                            showTagsByDefault={!this.props.tagPage}
                        />
                    </div>
                    <div style={displayIf(this.props.redoCrop !== undefined)}>
                        <button
                            className="bg-red-600 text-white border rounded p-2 hover:bg-red-800"
                            onClick={this.props.redoCrop}>
                            Change cropped image
                        </button>
                    </div>
                </div>
                {transition(
                    this.state.showDeleteThreadWarning,
                    this.cancelDelete,
                    "sm:max-w-lg",
                    (<div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Remove Last Tag</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Each MarqLink must have at least one tag, or it will be deleted.
                        Would you like to delete this Marq Link?
                    </p>
                    </div>
                    <div className="flex flex-row p-2">
                        <button
                        onClick={this.deleteThread}
                        className="w-full flex justify-center m-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700"
                        >
                        Delete MarqLink with {maybePluralize(this.props.thread.comments.length, "comment")}.
                        </button>
                        <button
                        onClick={this.cancelDelete}
                        className="w-full flex justify-center m-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-gray-300 hover:bg-gray-400"
                        >
                        Cancel
                        </button>
                    </div>

                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                    
                    </div>
                </div>
                    )
                )}
            </div>
        );
    }
}

