import React, { Component } from 'react';
import { Fragment } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import axios from 'axios';
import { maybePluralize } from './dates';

import {
    ShareIcon,
    PencilIcon,
    UserIcon,
  } from '@heroicons/react/solid'
import { TagDetails } from './types';
import { ShareDialog } from './share_dialog';
import { closeButton } from './close_button';
import { transition } from './transitions';
import { classNames } from './tailwind';
import * as amplitude from '@amplitude/analytics-browser';

type TagHeaderProps = {
    tagDetails: TagDetails,
    refreshTags: () => void,
}

type TagHeaderState = {
    name: string,
    description: string,
    editing: boolean,
    sharing: boolean,
    showDeleteTag: boolean,
    ordering: string,
}
  
export class TuiTagHeader extends Component<TagHeaderProps, TagHeaderState> {
    constructor(props: TagHeaderProps) {
        super(props);
        const tag = props.tagDetails;
        this.state = {
            name: tag.name,
            description: tag.description,
            editing: false,
            sharing: false,
            showDeleteTag: false,
            ordering: tag.ordering,
        }
    }

    edit = () => {
        this.setState({
            editing: true,
        });
    }
    onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.target.value,
        });
    }
    onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            description: event.target.value,
        });
    }
    updateTag() {
        axios({
            url: '/update_tag',
            method: 'post',
            data: JSON.stringify({
                tagId: this.props.tagDetails.tagId,
                name: this.state.name,
                description: this.state.description,
                ordering: this.state.ordering,
            }),
        }).then(() => {
            this.props.refreshTags();
        });
    }
    closeEdits = () => {
        this.setState({
            editing: false,
        });
    }
    saveEdits = () => {
        this.setState({
            editing: false,
        });
        this.updateTag();
    }
    openShare = () => {
        amplitude.track('Open Share');
        this.setState({
            sharing: true,
        });
    }
    closeShare = () => {
        this.setState({
            sharing: false,
        });
    }
    showDeleteTag = () => {
        this.setState({showDeleteTag: true});
    }
    cancelDeleteTag = () => {
        this.setState({showDeleteTag: false});
    }
    deleteTag = () => {
        axios({
            url: '/delete_tag',
            method: "POST",
            data: JSON.stringify({
                tag_id: this.props.tagDetails.tagId,
            }),
        }).then(() => {
            window.location.href = "/";
        });
    }

    setOrdering = (newOrdering: string) => {
        this.setState({ordering: newOrdering});
    }

    render() {
        let orderingSettings = [
            {
                name: 'Newest Link First',
                value: 'newest_first',
                description: 'The links shown will be ordered with the most recently created at the top of the page.'
            },
            {
                name: 'Oldest Link First',
                value: 'oldest_first',
                description: 'The links shown will be ordered with the first created at the top of the page.'
            },
        ]
        return (
            
        <div className="lg:flex lg:items-center lg:justify-between bg-gray-800 px-8 py-2">
            <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-white text-left sm:text-3xl sm:truncate">{this.props.tagDetails.name}</h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                <div className="mt-2 flex items-center text-left text-sm text-gray-300 whitespace-pre-wrap">
                    {this.props.tagDetails.description}
                </div>

                <div
                    className="mt-2 flex items-center text-sm text-gray-300">
                    <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                    {this.props.tagDetails.editors > 1 ? 'Tag is shared with ' : ''}
                    <button className="underline ml-1" onClick={this.openShare}>
                        {this.props.tagDetails.editors > 1 ?
                        maybePluralize(this.props.tagDetails.editors - 1, ' other user')
                        : 'Tag is private'}
                    </button>
                </div>
            </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
            <span className="sm:block">
                <button
                type="button"
                onClick={this.edit}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-300" aria-hidden="true" />
                Edit
                </button>
            </span>
            <span className="ml-3">
                <button
                type="button"
                onClick={this.openShare}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                <ShareIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Share
                </button>
            </span>
            </div>
            <Transition.Root show={this.state.editing} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={this.closeEdits}>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                        <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                            <div className="space-y-8 divide-y divide-gray-200">
                                {closeButton(this.closeEdits)}
                                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="space-y-6 sm:space-y-5">
                                    <div>
                                        <h3 className="text-lg my-2 leading-6 font-medium text-gray-900">Edit Tag</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Tags make your links easy to find and organize.</p>
                                    </div>
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Tag Name
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="name"
                                            value={this.state.name}
                                            onChange={this.onChangeName}
                                            className="max-w-lg p-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                                            />
                                        </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Description
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <textarea
                                            rows={3}
                                            name="desc"
                                            id="desc"
                                            value={this.state.description}
                                            onChange={this.onChangeDescription}
                                            autoComplete="description"
                                            className="max-w-lg p-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                                            />
                                        </div>
                                        </div>
                                        <RadioGroup value={this.state.ordering} onChange={this.setOrdering}>
                                            <RadioGroup.Label className="text-sm font-medium text-gray-900">Link Ordering</RadioGroup.Label>

                                            <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                                                {orderingSettings.map((setting, settingIdx) => (
                                                <RadioGroup.Option
                                                    key={setting.name}
                                                    value={setting.value}
                                                    className={({ checked }) =>
                                                    classNames(
                                                        settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                        settingIdx === orderingSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                        checked ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
                                                        'relative border p-4 flex cursor-pointer focus:outline-none'
                                                    )
                                                    }
                                                >
                                                    {({ active, checked }) => (
                                                    <>
                                                        <span
                                                        className={classNames(
                                                            checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                                            active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                                                            'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                                                        )}
                                                        aria-hidden="true"
                                                        >
                                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                        </span>
                                                        <span className="ml-3 flex flex-col">
                                                        <RadioGroup.Label
                                                            as="span"
                                                            className={classNames(
                                                            checked ? 'text-blue-900' : 'text-gray-900',
                                                            'block text-sm font-medium'
                                                            )}
                                                        >
                                                            {setting.name}
                                                        </RadioGroup.Label>
                                                        <RadioGroup.Description
                                                            as="span"
                                                            className={classNames(checked ? 'text-blue-700' : 'text-gray-500', 'block text-sm')}
                                                        >
                                                            {setting.description}
                                                        </RadioGroup.Description>
                                                        </span>
                                                    </>
                                                    )}
                                                </RadioGroup.Option>
                                                ))}
                                            </div>
                                            </RadioGroup>
                                    </div>
                                    <button
                                        onClick={this.showDeleteTag}
                                        className="font-medium text-red-800 hover:text-indigo-500">
                                        I want to delete this tag.
                                    </button>
                                    </div>
                                </div>
                            </div>

                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                onClick={this.saveEdits}
                            >
                                Save Edits
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                onClick={this.closeEdits}
                            >
                                Cancel
                            </button>
                            </div>
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <ShareDialog
                sharing={this.state.sharing}
                tagDetails={this.props.tagDetails}
                closeShareFn={this.closeShare}
                refreshTags={this.props.refreshTags}
            />
            {transition(
                this.state.showDeleteTag,
                this.cancelDeleteTag,
                "sm:max-w-lg",
                (
                    <div className="min-h-full flex flex-col justify-center py-4 sm:px-2 lg:px-6">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Delete Tag</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Would you like to delete this tag and any Marq Link associated with only this tag?
                            Marq Links associated with multiple tags will not be deleted.
                        </p>
                        </div>
                        <div className="flex flex-row p-2">
                            <button
                            onClick={this.deleteTag}
                            className="w-full flex justify-center m-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700"
                            >
                            Delete tag with {maybePluralize(this.props.tagDetails.threadCount, "link")}
                            </button>
                            <button
                            onClick={this.cancelDeleteTag}
                            className="w-full flex justify-center m-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-gray-300 hover:bg-gray-400"
                            >
                            Cancel
                            </button>
                        </div>

                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                        
                        </div>
                    </div>
                )
            )}
        </div>
        )
    }
  }