import React, { Component } from 'react';
import { Comment, Reaction }  from './types';
import { AddReactionOutlined, Edit } from '@mui/icons-material';
import displayIf from './display_if';
import { CommentEdit } from './comment_edit';
import { timeSince } from './dates';
import { BellIcon } from '@heroicons/react/solid';
import EmojiPicker, { Emoji, EmojiClickData } from 'emoji-picker-react';
import { classNames } from './tailwind';

type CommentProps = {
    comment: Comment,
    updateThreadFn: (commentIndex: number, comment: Comment) => void,
    commentIndex: number,
    threadId: string,
    currentUsername: string,
    notificationTime: number | null,
}

type CommentState = {
    editing: boolean,
    showDeleteOptions: boolean,
    pickingEmoji: boolean,
}

export class CommentBox extends Component<CommentProps, CommentState> {
    constructor(props: CommentProps) {
        super(props);
        this.state = {
            editing: false,
            showDeleteOptions: false,
            pickingEmoji: false,
        };
    }
    editComment = () => {
        this.setState({
            editing: !this.state.editing,
        });
    }

    cancelEdit = () => {
        this.setState({
            editing: false,
        });
    }

    updateThread = (commentText: string) => {
        var comment: Comment = this.props.comment;
        comment.text = commentText;
        if (commentText.length == 0) {
            comment.reactions = [];
        }
        this.props.updateThreadFn(this.props.commentIndex, comment);
        this.cancelEdit();
        return true;
    }

    mouseEnterDelete = () => {
        this.setState({showDeleteOptions: true});
    }

    mouseLeaveDelete = () => {
        this.setState({showDeleteOptions: false});
    }

    clickEmoji = () => {
        this.setState({pickingEmoji: !this.state.pickingEmoji});
    }

    pickedEmoji = (emojiData: EmojiClickData, event: MouseEvent) => {
        var comment = this.props.comment;
        if (!comment.reactions) {
            comment.reactions = [];
        }
        comment.reactions.push({
            username: this.props.currentUsername,
            emojiCode: emojiData.unified,
        });
        this.setState({pickingEmoji: false});
        this.props.updateThreadFn(this.props.commentIndex, comment);
    }

    removeEmoji(index: number) {
        var comment = this.props.comment;
        if (!comment.reactions) return;
        comment.reactions.splice(index, 1);
        this.props.updateThreadFn(this.props.commentIndex, comment);
    }

    render() {
        const text = this.props.comment.text;
        let reactions: Array<JSX.Element> = [];
        if (this.props.comment.reactions !== undefined) {
            this.props.comment.reactions.forEach((reaction: Reaction, i: number) => {
                reactions.push(
                <span key={i}
                    className="inline-flex items-center rounded-full bg-indigo-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-indigo-700">
                    <Emoji unified={reaction.emojiCode} />
                    <span className="pl-1">{reaction.username}</span>
                    <button
                    type="button"
                    onClick={() => this.removeEmoji(i)}
                    className={classNames("ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full",
                        "text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500",
                        "focus:bg-indigo-500 focus:text-white focus:outline-none",
                        reaction.username !== this.props.currentUsername ? "hidden" : "",
                    )}
                    >
                    <span className="sr-only">Remove emoji</span>
                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                    </button>
                </span>
                );
            });
        }
        return (
            <div className="font-normal text-sm py-1">
                <div className="text-left group">
                    <div className="text-blue-700 font-bold flex flex-row justify-between relative">
                        <div className="font-medium">
                            {this.props.comment.author}
                            <div className="text-gray-500 mx-4 inline font-normal">
                                {timeSince(new Date(this.props.comment.editTime))
                            } ago</div>
                            {
                                this.props.notificationTime !== null && this.props.notificationTime < (this.props.comment.editTime + 5000) ?
                                (
                                    <div className="flex inline-flex flex-row text-green-300 font-normal">
                                        <BellIcon className="-ml-1 mr-0.5 pt-0.5 h-4 w-4" aria-hidden="true" />
                                        <span>NEW</span>
                                    </div>
                                )
                                : null
                            }
                        </div>
                        <div className="flex-row flex opacity-0 group-hover:opacity-100 ">
                            <button
                                className="text-gray-300 hover:text-gray-500"
                                onClick={this.clickEmoji}
                            >
                                <AddReactionOutlined />
                            </button>
                            <div style={displayIf(this.props.currentUsername === this.props.comment.author)}>
                                <button onClick={this.editComment}
                                    className="text-blue-500 hover:text-blue-700"
                                    data-tip="Edit comment">
                                    <Edit />
                                </button>
                            </div>
                        </div>
                    </div>
                    {this.state.pickingEmoji ? 
                    <EmojiPicker 
                        onEmojiClick={this.pickedEmoji}
                    /> : null}
                    <div className="text-left my-2 whitespace-pre-wrap"
                        style={displayIf(!this.state.editing)}>
                        {text}
                    </div>
                    <div className="text-left m-2" style={displayIf(this.state.editing)}>
                        <CommentEdit
                            postReply={this.updateThread}
                            cancel={this.cancelEdit}
                            startingContent={this.props.comment.text}
                            isNewThread={false}
                            commentId={this.props.commentIndex.toString() + '-' + this.props.threadId}
                            updateMode={true}
                        />
                    </div>
                    <div>
                        {reactions}
                    </div>
                </div>
            </div>
        );
    }
}