import React, { Component } from 'react';
import { LoginState, setLoginState } from './user';
import { Footer } from './footer'
import { Header } from './tui_header';
import * as amplitude from '@amplitude/analytics-browser';
import { PlayIcon } from '@heroicons/react/solid';
import { transition } from './transitions';

type WelcomeProps = {
    showLogin: boolean,
    signupToken: string,
};
type WelcomeState = LoginState & {
    showLoginOverlay: boolean,
    playPromoVideo: boolean,
};

export class Welcome extends Component<WelcomeProps, WelcomeState> {
    constructor(props: WelcomeProps) {
        super(props);
        this.state = {
            loggedIn: false,
            username: '',
            email: '',
            showLoginOverlay: this.props.showLogin,
            logoutFn: () => {},
            userImageUrl: '',
            playPromoVideo: false,
        };
    }

    signUp = () => {
        this.setState({
            showLoginOverlay: true,
        });
        amplitude.track('Welcome Sign Up');
        console.log("Pushed login");
    }
    stopLoggingIn = () => {
        this.setState({
            showLoginOverlay: false,
        });
    }

    componentDidMount(): void {
        setLoginState(this);
        amplitude.track('Welcome');
    }
    playPromoVideo = () => {
        this.setState({
            playPromoVideo: true,
        });
    }
    stopPromoVideo = () => {
        this.setState({
            playPromoVideo: false,
        });
    }

    render() {
        return (
            <div className="bg-white">
                <Header
                    loggedIn={this.state.loggedIn}
                    username={this.state.username}
                    signupToken={this.props.signupToken}
                    logoutFn={this.state.logoutFn}
                    initiallyShowLoginOverlay={this.state.showLoginOverlay}
                    userImageUrl={this.state.userImageUrl}
                    onDismissLoginOverlay={this.stopLoggingIn}
                />

                {/* Hero section */}
                <div className="relative">
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                    <div className="absolute inset-0">
                        <img
                        className="h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                        alt="People working on laptops"
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
                    </div>
                    <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                        <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                        <span className="block text-white">Organize your content</span>
                        <span className="block text-indigo-200">from across the web</span>
                        </h1>
                        <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                            Marq Links enables you to capture screenshots, annotate them, and share with your friends and colleagues.
                        </p>
                        <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                        <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                            <button
                                onClick={this.signUp}
                                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                                >
                                Get started for free
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* Alternating Feature Sections */}
                <div className="relative pt-16 pb-32 overflow-hidden">
                <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
                <div className="relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                        {/* <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
                            <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                        </div> */}
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Snapshot and crop any page on the Internet
                            </h2>
                            <p className="mt-4 text-lg text-gray-500">
                            You can use the Marq Link extension to quickly screenshot, tag, and add comments for
                            any web content you want to remember or discuss. Screenshots are great for getting
                            back to content that may be behind many layers of logins and recording exactly what
                            you saw.
                            </p>
                            <div className="mt-6">
                            <button
                                onClick={this.signUp}
                                className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                            >
                                Get started
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="relative pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            {/* <Player
                                src="https://d308zvlq21xad2.cloudfront.net/images/MarqlinkPromoVideo.mp4"
                                poster="https://d308zvlq21xad2.cloudfront.net/images/couchscreenshot911.png"
                            /> */}
                            {/* <video controls>
                                <source src="https://d308zvlq21xad2.cloudfront.net/images/MarqlinkPromoVideo.mp4" />
                            </video> */}
                            <img
                                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                // src="https://d308zvlq21xad2.cloudfront.net/images/couchscreenshot911.png"
                                src="https://d308zvlq21xad2.cloudfront.net/images/MarqLinkDiagram2.png"
                                alt="Screenshot with the extension"
                            />
                            <button className="absolute z-10 inset-0 flex items-center justify-center"
                                onClick={this.playPromoVideo}
                            >
                                <PlayIcon className="h-16 w-16 text-gray-300 hover:text-gray-500 drop-shadow-lg" />
                            </button>
                            {
                                transition(this.state.playPromoVideo, this.stopPromoVideo, "", (
                                    <video controls autoPlay className="w-screen">
                                        <source src="https://d308zvlq21xad2.cloudfront.net/images/MarqlinkPromoVideo.mp4"/>
                                    </video>
                                ))
                            }
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Group Marq Links into Tags
                            </h2>
                            <p className="mt-4 text-lg text-gray-500">
                            Your Marq Link screenshots are collected by tag. This makes it easy to organize your online life and quickly find your useful links or compare options.
                            </p>
                            <div className="mt-6">
                            <button
                                onClick={this.signUp}
                                className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                            >
                                Organize better
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <img
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                            src="https://d308zvlq21xad2.cloudfront.net/images/tagpage.png"
                            alt="Customer profile user interface"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="relative mt-12">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                        {/* <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
                            <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                        </div> */}
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Collaborate with friends and coworkers
                            </h2>
                            <p className="mt-4 text-lg text-gray-500">
                            You can share these tag pages and discuss with others.
                            You can collaborate on work projects, plan trips with friends, or find your own use case!
                            </p>
                            <p className="mt-4 text-lg text-gray-500">MarqLinks are private by default,
                            so you have full control over what you share and with whom.
                            </p>
                            <div className="mt-6">
                            <button
                                onClick={this.signUp}
                                className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                            >
                                Start collaborating
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <img
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                            src="https://d308zvlq21xad2.cloudfront.net/images/sharing.png"
                            alt="Screenshot with the extension"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <Footer />
            </div>
        );
    }
}