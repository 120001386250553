import React, { Component, Fragment } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { Editor, TagDetails } from './types';
import { CheckCircleIcon, MailIcon, MinusSmIcon, XCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { maybePluralize } from './dates';
import { closeButton } from './close_button';
import { MailOpenIcon } from '@heroicons/react/outline';
import * as amplitude from '@amplitude/analytics-browser';

type ShareDialogProps = {
    sharing: boolean,
    tagDetails: TagDetails,
    closeShareFn: () => void,
    refreshTags: () => void,
}

type ShareDialogState = {
    newUser: string,
    connectedEmails: Array<string>,
    editors: Array<Editor>,
    searchOpen: boolean,
    newShareList: Array<string>,
    emailText: string,
    emailedList: Array<string>,
    shareToken: string,
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export class ShareDialog extends Component<ShareDialogProps, ShareDialogState> {
    constructor(props: ShareDialogProps) {
        super(props);
        this.state = {
            newUser: '',
            connectedEmails: [],
            editors: [],
            searchOpen: false,
            newShareList: [],
            emailText: '',
            emailedList: [],
            shareToken: '',
        }
    }

    componentDidMount(): void {
        axios({
            method: 'get',
            url: '/get_connected_users',
        }).then((response) => {
            if (response.status === 200) {
                let emails = [];
                response.data.emails.forEach((user) => {
                    emails.push(user.email);
                });
                this.setState({
                    connectedEmails: emails,
                });
            }
        });
        axios({
            method: 'get',
            url: '/get_tag_editors?tag_id=' + this.props.tagDetails.tagId,
        }).then((response) => {
            if (response.status === 200) {
                this.setState({
                    editors: response.data.editors,
                });
            }
        });
        axios({
            method: 'post',
            url: '/generate_share_token',
            data: JSON.stringify({
                tag_id: this.props.tagDetails.tagId,
            }),
        }).then((response) => {
            if (response.status === 200) {
                this.setState({
                    shareToken: response.data.token,
                });
            }
        });
    }

    onChangeShare = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            newUser: event.target.value,
        });
    }

    openSearch = () => {
        console.log('Focused search');
        this.setState({
            searchOpen: true,
        });
    }

    closeSearch = () => {
        this.setState({
            searchOpen: false,
        });
    }

    shareWithUsers = async () => {
        for (let i = 0; i < this.state.newShareList.length; i++) {
            const email = this.state.newShareList[i];
            const response = await axios({
                method: 'post',
                url: '/share_tag',
                data: JSON.stringify({
                    tag_id: this.props.tagDetails.tagId,
                    share_with_username: email,
                    message: this.state.emailText,
                })
            });
            amplitude.track('Shared Tag');
            if (response.status !== 200) return;
        }
        // this.props.closeShareFn();
        this.props.refreshTags();
        this.setState({
            newShareList: [],
            emailedList: this.state.emailedList.concat(this.state.newShareList),
            searchOpen: false,
        })
    }
    keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.selectNewUser(this.state.newUser);
        }
    }
    changeEmailText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            emailText: e.target.value,
        });
    }

    selectNewUser = (email: string) => {
        let newShareList = this.state.newShareList;
        newShareList.push(email);
        this.setState({
            searchOpen: false,
            newShareList: newShareList,
            newUser: '',
        });
    }

    cancelShareWithUser = (email: string) => {
        this.setState({
            newShareList: this.state.newShareList.filter((e) => e !== email),
        });
    }

    render() {
        let filteredPeople = this.state.connectedEmails.filter(e => this.state.newUser === '' ||
            e.toLowerCase().indexOf(this.state.newUser.toLowerCase()) !== -1);
        let shareLink = "https://www.marq.link/tag?tag=" + this.props.tagDetails.tagId +
                "&key=" + this.state.shareToken;
        return (
            <Transition.Root show={this.props.sharing} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={this.props.closeShareFn} initialFocus={undefined}>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                        <Dialog.Panel
                            className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                        <div className="max-w-lg mx-auto">
                            {closeButton(this.props.closeShareFn)}
                            <div className="">
                                <div className="text-center">
                                <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                                    />
                                </svg>
                                <h2 className="mt-2 text-lg font-medium text-gray-900">Share this tag</h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    The tag <a
                                        className="hover:underline font-bold text-black"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/tag?tag=" + this.props.tagDetails.tagId}>
                                            {this.props.tagDetails.name}
                                        </a> is shared with {maybePluralize(this.props.tagDetails.editors - 1, 'user')} other than yourself. Sharing gives others access to view, comment, and add links to a tag.
                                </p>
                                </div>
                                <div className="mt-10">
                                    <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
                                    Share with
                                    </h3>
                                    <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                    {this.state.newShareList.map((email, personIdx) => (
                                        <li key={personIdx} className="py-4 flex items-center justify-between space-x-3">
                                        <div className="min-w-0 flex-1 flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                            {/* <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" /> */}
                                            </div>
                                            <div className="min-w-0 flex-1">
                                            <p className="text-sm font-medium text-gray-900 truncate">{email}</p>
                                            {/* <p className="text-sm font-medium text-gray-500 truncate">{person.role}</p> */}
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <button
                                            type="button"
                                            onClick={() => this.cancelShareWithUser(email)}
                                            className="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                            <MinusSmIcon className="-ml-1 mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span className="text-sm font-medium text-gray-900">
                                                {' '}
                                                Cancel <span className="sr-only">{email}</span>{' '}
                                            </span>
                                            </button>
                                        </div>
                                        </li>
                                    ))}
                                    </ul>
                                    <div className="mx-auto my-3 transform rounded-lg divide-y divide-gray-300 border border-gray-300 shadow-sm ring-1 ring-black ring-opacity-5 transition-all"
                                    >
                                        <Combobox 
                                            value={this.state.newUser} onChange={this.selectNewUser}>
                                            <div className="relative">
                                            <MailIcon
                                                className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <Combobox.Input
                                                className="w-full h-12 border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                                                placeholder={"add " + (this.state.newShareList.length > 0 ? "another" : "an") + " email address..."}
                                                value={this.state.newUser}
                                                onChange={this.onChangeShare}
                                                onFocus={this.openSearch}
                                                onKeyPress={this.keyPress}
                                            />
                                            {this.state.searchOpen && <button
                                                onClick={this.closeSearch}
                                                className="absolute top-3.5 right-4 h-5 w-5 text-gray-400"
                                                >
                                                <XCircleIcon
                                                    aria-hidden="true"
                                                />
                                            </button>}
                                            </div>

                                            {this.state.searchOpen && (
                                                // For covering contents below, use: fixed bg-white border border-black rounded w-full 
                                            <Combobox.Options static className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                                                {filteredPeople.filter((person) => {return !this.state.newShareList.includes(person)}).map((person, personIdx) => (
                                                <Combobox.Option
                                                    key={personIdx}
                                                    value={person}
                                                    className={({ active }) =>
                                                    classNames('cursor-default select-none px-4 py-2 flex flex-row', active && 'bg-indigo-600 text-white')
                                                    }
                                                >
                                                    <div className='flex-grow'>{person}</div>
                                                    {this.state.editors.some((e) => e.email === person) ?
                                                        (<CheckCircleIcon className="display-inline -ml-1 mr-0.5 h-5 w-5 text-green-400" aria-hidden="true" />) :
                                                        null}
                                                </Combobox.Option>
                                                ))}
                                                {filteredPeople.length === 0 && (
                                                <Combobox.Option
                                                    value={this.state.newUser}
                                                    className={({ active }) =>
                                                    classNames('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                                                    }
                                                >
                                                    Invite {this.state.newUser}
                                                </Combobox.Option>
                                                )}
                                            </Combobox.Options>
                                            )}
                                        </Combobox>
                                    </div>
                                    {this.state.newShareList.length > 0 && (
                                    <div className="flex items-start space-x-4">
                                        <div className="min-w-0 flex-1 relative">
                                            <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden">
                                                <label htmlFor="comment" className="sr-only">
                                                Add your message
                                                </label>
                                                <textarea
                                                rows={3}
                                                name="comment"
                                                id="comment"
                                                value={this.state.emailText}
                                                onChange={this.changeEmailText}
                                                className="block text-sm w-full p-2 focus:outline-0 border-none rounded-lg focus:ring-0 resize-none sm:text-sm"
                                                placeholder="Write your message (optional)..."
                                                autoFocus
                                                />

                                                {/* Spacer element to match the height of the toolbar */}
                                                <div
                                                    className="py-2" aria-hidden="true">
                                                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                                <div className="py-px">
                                                    <div className="h-9" />
                                                </div>
                                                </div>
                                            </div>

                                            <div
                                                className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                                                <div className="flex flex-grow items-center space-x-5">
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <button
                                                        type="submit"
                                                        onClick={this.shareWithUsers}
                                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        Share with {maybePluralize(this.state.newShareList.length, "new user")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            {this.state.emailedList.length > 0 && (
                            <div className="mt-10 min-h-[36rem]">
                                <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
                                Email Just Sent
                                </h3>
                                <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                {this.state.emailedList.map((email, personIdx) => (
                                    <li key={personIdx} className="py-4 flex items-center justify-between space-x-3">
                                    <div className="min-w-0 flex-1 flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                        {/* <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" /> */}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900 truncate">{email}</p>
                                        {/* <p className="text-sm font-medium text-gray-500 truncate">{person.role}</p> */}
                                        </div>
                                        <div
                                            className="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                            <MailOpenIcon className="-ml-1 mr-1 h-5 w-5 text-white" aria-hidden="true" />
                                            <span className="text-sm font-medium text-white">
                                                Shared
                                            </span>
                                        </div>
                                    </div>
                                    </li>
                                ))}
                                </ul>
                            </div>)}
                            <div className="mt-10 min-h-[36rem]">
                                <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
                                People with access already
                                </h3>
                                <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                {this.state.editors.map((person, personIdx) => (
                                    <li key={personIdx} className="py-4 flex items-center justify-between space-x-3">
                                    <div className="min-w-0 flex-1 flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                        {/* <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" /> */}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900 truncate">{person.email}</p>
                                        {/* <p className="text-sm font-medium text-gray-500 truncate">{person.role}</p> */}
                                        </div>
                                        <CheckCircleIcon className="-ml-1 mr-0.5 h-5 w-5 text-green-400" aria-hidden="true" />
                                    </div>
                                    </li>
                                ))}
                                </ul>
                            </div>
                            {this.state.shareToken === '' ? null :
                            <div className="mt-10 min-h-[36rem]">
                                <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
                                Shareable link
                                </h3>
                                <input className="text-sm font-normal text-gray-900 truncate w-full" type="text" value={shareLink}>
                                </input>
                            </div>} 
                            </div>
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}