import React, { Component } from 'react';
import { LoginState, setLoginState } from './user';
import { Footer } from './footer'
import { Header } from './tui_header';
import * as amplitude from '@amplitude/analytics-browser';

type ExtensionInstalledProps = {};
type ExtensionInstalledState = LoginState & {};

export class ExtensionInstalled extends Component<ExtensionInstalledProps, ExtensionInstalledState> {
    constructor(props: ExtensionInstalledProps) {
        super(props);
        this.state = {
            loggedIn: false,
            username: '',
            email: '',
            logoutFn: () => {},
            userImageUrl: '',
        };
    }

    componentDidMount(): void {
        setLoginState(this);
        amplitude.track('Extension Installed');
    }

    render() {
        return (
            <div className="bg-gray-200 h-screen">
              <div className="flex flex-col border-b border-gray-200 lg:border-0 bg-gray-100 max-w-screen-lg mx-auto">
                <Header
                    loggedIn={this.state.loggedIn}
                    username={this.state.username}
                    logoutFn={this.state.logoutFn}
                    initiallyShowLoginOverlay={false}
                    userImageUrl={this.state.userImageUrl}
                />
                    <div className="relative lg:grid lg:grid-cols-2">
                    <div className="relative bg-gray-100 lg:bg-transparent lg:col-span-1">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl py-24 lg:max-w-none lg:py-32">
                            
                            <div className="lg:pr-16">
                            <p className="mt-4 text-xl text-gray-600">
                                Thanks for installing!
                            </p>
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl my-6">
                                Now, get the most out of Marq Link
                            </h1>
                            <p className="mt-4 text-xl text-gray-600">
                                Pin Marq Link in your extensions bar.
                                Simply click on the extensions puzzle piece on the top right of your browser,
                                then click the thumbtack next to the Marq Link logo to pin it.
                            </p>
                            <p className="mt-4 text-xl text-gray-600">
                                Now, any time you want to remember or share information from a website, you can just click the
                                <img
                                    src="https://d308zvlq21xad2.cloudfront.net/images/favicon-32x32.png"
                                    alt="MarqLink icon"
                                    className="inline -mt-1 ml-2 mr-1 h-6 w-6"
                                />!
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="w-full lg:col-start-2 lg:col-span-1 justify-center p-4 my-auto">
                        <img
                        src="https://d308zvlq21xad2.cloudfront.net/images/pin_extension.png"
                        alt=""
                        className="w-full object-cover object-center"
                        />
                    </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}