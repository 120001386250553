import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { closeButton } from './close_button';

type FeedbackProps = {
    closeFeedback: () => void,
    enabled: boolean,
}
type FeedbackState = {
    subject: string,
    description: string,
}

export class Feedback extends Component<FeedbackProps, FeedbackState> {
    constructor(props: FeedbackProps) {
        super(props);
        this.state = {
            subject: '',
            description: '',
        }
    }

    onChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({subject: event.target.value});
    }

    onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({description: event.target.value});
    }

    submitFeedback = () => {
        axios({
            url: '/send_feedback',
            method: 'post',
            data: JSON.stringify({
                subject: this.state.subject,
                description: this.state.description,
            })
        }).then(() => {
            this.setState({
                subject: '',
                description: '',
            });
            this.props.closeFeedback();
        });
    }

    render() {
        return (
      <Transition.Root show={this.props.enabled} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={this.props.closeFeedback}>
          <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                  {closeButton(this.props.closeFeedback)}
                  <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 sm:space-y-5">
                          <div>
                              <h3 className="text-lg my-2 leading-6 font-medium text-gray-900">Provide feedback to the MarqLink team</h3>
                              <p className="mt-1 max-w-2xl text-sm text-gray-500">Let us know how we can make MarqLink better!</p>
                          </div>
                          <div className="space-y-6 sm:space-y-5">
                              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                  Subject
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <input
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  value={this.state.subject}
                                  onChange={this.onChangeSubject}
                                  className="max-w-lg p-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                                  />
                              </div>
                              </div>

                              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                  Description
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <textarea
                                  rows={3}
                                  name="desc"
                                  id="desc"
                                  value={this.state.description}
                                  onChange={this.onChangeDescription}
                                  autoComplete="description"
                                  className="max-w-lg p-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border border-gray-300 rounded-md"
                                  />
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </form>

                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      disabled={this.state.description.length === 0}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      onClick={this.submitFeedback}
                  >
                      Submit Feedback
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={this.props.closeFeedback}
                  >
                      Cancel
                  </button>
                  </div>
              </Dialog.Panel>
              </Transition.Child>
          </div>
          </div>
      </Dialog>
  </Transition.Root>
        );
    }
};