import { Dialog, Disclosure, Switch, Transition } from '@headlessui/react';
import { CogIcon, UserCircleIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Footer } from './footer';
import { classNames } from './tailwind';
import { Header } from './tui_header';
import { LoginState, setLoginState } from './user';

enum SettingGroup {
  Profile = 0,
  Account = 1,
}
type ProfileProps = {}
type ProfileState = LoginState & {
    emailOnTagShare: boolean,
    emailOnComment: boolean,
    emailOnLink: boolean,
    firstName: string,
    lastName: string,
    settingGroup: SettingGroup,
    showDeleteDialog: boolean,
    newProfileImage?: File,
}

export class Profile extends Component<ProfileProps, ProfileState> {
  constructor(props: ProfileProps) {
      super(props);
      this.state = {
          loggedIn: true,
          username: '',
          email: '',
          logoutFn: () => {},
          userImageUrl: '',
          emailOnTagShare: true,
          emailOnComment: true,
          emailOnLink: true,
          firstName: '',
          lastName: '',
          settingGroup: this.settingGroup(),
          showDeleteDialog: false,
      }
  }

  componentDidMount() {
    setLoginState(this).then(() => {
      // Lookup user settings
      axios.get('/get_user_settings')
      .then((response) => {
        this.setState({
          emailOnTagShare: response.data.notify_on_tag_share,
          emailOnComment: response.data.notify_on_comment,
          emailOnLink: response.data.notify_on_new_link,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        });
      });
    });
    window.onhashchange = this.onHashChange;
  }

  componentWillUnmount() {
      window.onhashchange = null;
  }

  settingGroup() {
    let hash = window.location.hash;
    if (hash === '#account') {
      return SettingGroup.Account;
    } else if (hash === '#profile') {
      return SettingGroup.Profile;
    }
    return SettingGroup.Profile;
  }

  onHashChange = () => {
    this.setState({
      settingGroup: this.settingGroup(),
    });
  }

  updateSetting = (key: string, stateKey: string, value: boolean) => {
    let update = {}
    update[key] = value;
    axios({
      url: '/update_user_setting',
      method: 'post',
      data: JSON.stringify(update)
    }).then(() => {
      let state = {};
      state[stateKey] = value;
      this.setState(state);
    });

  }

  save = () => {
    let formData = new FormData();
    if (this.state.newProfileImage) {
      formData.append("profile_image", this.state.newProfileImage);
    }
    formData.append("first_name", this.state.firstName);
    formData.append("last_name", this.state.lastName);
    axios({
      url: '/save_user_settings',
      method: 'post',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(() => {
      window.location.reload();
    });
  }

  changeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      firstName: event.target.value,
    });
  }

  changeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      lastName: event.target.value,
    });
  }

  openDeleteDialog = () => {
    this.setState({
      showDeleteDialog: true,
    });

  }

  closeDeleteDialog = () => {
    this.setState({
      showDeleteDialog: false,
    });
  }

  deleteAccount = () => {
    axios({
        url: '/delete_account',
        method: "POST",
    }).then(() => {
        window.location.href = "/welcome";
    });
  }

  uploadNewProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (!file) return;
    if (!/^image\//i.test(file.type)) return;
    this.setState({
      newProfileImage: file,
    });
    // let reader = new FileReader();
    // let tagPage = this;
    // reader.onloadend = function() {
    //     const dataUrl: string | ArrayBuffer = reader.result;
    //     if (typeof(dataUrl) === 'string') {
    //         tagPage.setState({
    //             newUploadDataUrl: dataUrl,
    //         });
    //     }
    // }
    // reader.readAsDataURL(file);
}

  render() {
      let user = {
          email: this.state.email,
          name: this.state.username,
          imageUrl: this.state.userImageUrl,
      };
      let subNavigation = [
          {
              name: "Profile",
              href: '#profile',
              current: this.state.settingGroup === SettingGroup.Profile,
              icon: UserCircleIcon,
          },
          {
              name: "Account",
              href: '#account',
              current: this.state.settingGroup === SettingGroup.Account,
              icon: CogIcon,
          },
          // {
          //     name: "Billing",
          //     href: '#billing',
          //     current: false,
          //     icon: CreditCardIcon,
          // },
      ];

      let notificationSwitches = [
        {
          title: "Email when a tag is shared",
          description: "Someone explicitly shared a tag with you.",
          value: this.state.emailOnTagShare,
          key: "notify_on_tag_share",
          stateKey: "emailOnTagShare"
        },
        {
          title: "Email on comment",
          description: "Someone added a comment in a tag with you.",
          value: this.state.emailOnComment,
          key: "notify_on_comment",
          stateKey: "emailOnComment"
        },
        {
          title: "Email when link added",
          description: "Someone added a new Marq Link to a tag with you.",
          value: this.state.emailOnLink,
          key: "notify_on_new_link",
          stateKey: "emailOnLink"
        }
      ];
      return (
          <div>
              <Header
                          loggedIn={this.state.loggedIn}
                          username={this.state.username}
                          logoutFn={this.state.logoutFn}
                          initiallyShowLoginOverlay={false}
                          userImageUrl={this.state.userImageUrl}
                    />
              <Disclosure as="div" className="relative overflow-hidden bg-sky-700 pb-32">
      {({ open }) => (
        <>
          {/* <nav
            className={classNames(
              open ? 'bg-sky-900' : 'bg-transparent',
              'relative z-10 border-b border-green-500 border-opacity-25 lg:border-none lg:bg-transparent'
            )}
          >
            

            <Disclosure.Panel className="bg-sky-900 lg:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-black bg-opacity-25' : 'hover:bg-sky-800',
                      'block rounded-md py-2 px-3 text-base font-medium text-white'
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-sky-800 pt-4 pb-3">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{user.name}</div>
                    <div className="text-sm font-medium text-sky-200">{user.email}</div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full p-1 text-sky-200 hover:bg-sky-800 hover:text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md py-2 px-3 text-base font-medium text-sky-200 hover:bg-sky-800 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </nav> */}
          <div
            aria-hidden="true"
            className={classNames(
              open ? 'bottom-0' : 'inset-y-0',
              'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
            )}
          >
            <div className="absolute inset-0 flex">
              <div className="h-full w-1/2" style={{ backgroundColor: '#0a527b' }} />
              <div className="h-full w-1/2" style={{ backgroundColor: '#065d8c' }} />
            </div>
            <div className="relative flex justify-center">
              <svg
                className="flex-shrink-0"
                width={1750}
                height={308}
                viewBox="0 0 1750 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#0369a1" />
                <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#065d8c" />
                <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0a527b" />
                <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#0a4f76" />
              </svg>
            </div>
          </div>
          <header className="relative py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Profile Settings</h1>
            </div>
          </header>
        </>
      )}
    </Disclosure>

    <main className="relative -mt-32">
      <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-green-50 border-green-500 text-green-700 hover:bg-green-50 hover:text-green-700'
                        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                      'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-green-500 group-hover:text-green-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside>

            {this.state.settingGroup !== SettingGroup.Profile ? '' :
            (<form className="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
              {/* Profile section */}
              <div className="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                  <h2 className="text-lg font-medium leading-6 text-gray-900">Profile</h2>
                  <p className="mt-1 text-sm text-gray-500">
                    This information will be displayed publicly so be careful what you share.
                  </p>
                </div>

                <div className="mt-6 flex flex-col lg:flex-row justify-center">
                  <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-8 sm:col-span-12">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        First name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        value={this.state.firstName}
                        onChange={this.changeFirstName}
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-8 sm:col-span-12">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        value={this.state.lastName}
                        onChange={this.changeLastName}
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>

                    {/* <div className="col-span-12 sm:col-span-6">
                      <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                        Company
                      </label>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="organization"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div> */}
                  </div>

                  <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
                    <p className="text-sm font-medium text-gray-700" aria-hidden="true">
                      Photo
                    </p>
                    <div className="mt-1 lg:hidden">
                      <div className="flex items-center">
                        <div
                          className="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full"
                          aria-hidden="true"
                        >
                          <img className="h-full w-full rounded-full" src={user.imageUrl} alt="" />
                        </div>
                        <div className="ml-5 rounded-md shadow-sm">
                          <div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
                            <label
                              htmlFor="mobile-user-photo"
                              className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700"
                            >
                              <span>Change</span>
                              <span className="sr-only"> user photo</span>
                            </label>
                            <input
                              id="mobile-user-photo"
                              name="user-photo"
                              type="file"
                              className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                              accept="image/*" onChange={(event) => this.uploadNewProfileImage(event)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative hidden overflow-hidden rounded-full lg:block">
                      <img className="relative h-40 w-40 rounded-full" src={user.imageUrl} alt="" />
                      <label
                        htmlFor="desktop-user-photo"
                        className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
                      >
                        <span>Change</span>
                        <span className="sr-only"> user photo</span>
                        <input
                          type="file"
                          id="desktop-user-photo"
                          name="user-photo"
                          className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                          accept="image/*" onChange={(event) => this.uploadNewProfileImage(event)}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                
              </div>

              {/* Notifications section */}
              <div className="divide-y divide-gray-200 pt-6">
                <div className="px-4 sm:px-6">
                  <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Notifications</h2>
                    <p className="mt-1 text-sm text-gray-500">
                          Notifications email you when someone shares a tag or updates a tag shared with you. You can change when Marq Links sends you these updates.
                    </p>
                  </div>
                  <ul className="mt-2 divide-y divide-gray-200">
                    {notificationSwitches.map((option) => { return (
                      <Switch.Group as="li" className="flex items-center justify-between py-4">
                        <div className="flex flex-col">
                          <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                            {option.title}
                          </Switch.Label>
                          <Switch.Description className="text-sm text-gray-500">
                            {option.description}
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={option.value}
                          onChange={(value) => this.updateSetting(option.key, option.stateKey, value)}
                          className={classNames(
                            option.value ? 'bg-green-500' : 'bg-gray-200',
                            'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2',
                            'border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2',
                            'focus:ring-sky-500 focus:ring-offset-2'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                                option.value ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                    )})}
                  </ul>
                </div>
                <div className="mt-4 flex justify-end py-4 px-4 sm:px-6">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={this.save}
                    className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
            )}
            {this.state.settingGroup !== SettingGroup.Account ? '' :
            (<div className="px-4 py-5 sm:p-6 lg:col-span-9">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Delete your account</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500 text-left">
                <p>Once you delete your account, you will lose all data associated with it.
                  Tags and links shared with other people will not be deleted.
                  Note that you can delete individual links by removing all their tags,
                  if you'd rather just delete some of your content.</p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  onClick={this.openDeleteDialog}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                >
                  Delete account
                </button>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </main>
    <Transition.Root show={this.state.showDeleteDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={this.closeDeleteDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Delete account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete your account? All of your data will be permanently removed
                        from our servers forever. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={this.deleteAccount}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={this.closeDeleteDialog}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
              <Footer />
          </div>
      );
  }
}