import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React from 'react';
import { classNames } from './tailwind';

function pageHash(pageSize: number, pageNumber: number) {
    return '#page=' + pageNumber + '&pageSize=' + pageSize;
}

export function Paginator(numResults: number, currentPage: number, pageSize: number) {
    if (numResults === 0) return null;
    const firstItemIndex = currentPage * pageSize + 1;
    const lastItemIndex = Math.min(numResults, (currentPage + 1) * pageSize);
    let indicesToShow = Math.ceil(numResults / pageSize);
    let pageIndices = new Array<number>();
    let didEllipsis = false;
    const ellipsisNumber = -1;
    for (let i = 0; i < indicesToShow; i++) {
        if (i === 0 || Math.abs(currentPage - i) < 3) {
            pageIndices.push(i);
            didEllipsis = false;
        } else if (!didEllipsis) {
            pageIndices.push(ellipsisNumber);
            didEllipsis = true;
        }
    }
    return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
            <a
            href={pageHash(pageSize, currentPage - 1)}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
            Previous
            </a>
            <a
            href={pageHash(pageSize, currentPage + 1)}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
            Next
            </a>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
            <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{firstItemIndex}</span> to <span className="font-medium">{lastItemIndex}</span> of{' '}
                <span className="font-medium">{numResults}</span> results
            </p>
            </div>
            <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <a
                href={pageHash(pageSize, currentPage - 1)}
                className={classNames("relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
                        currentPage === 0 ? "pointer-events-none" : "",
                        currentPage === 0 ? "hidden" : "")}
                >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {pageIndices.map((index) => {
                    if (index === ellipsisNumber) {
                        return (
                            <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                            ...
                            </span>
                        );
                    }
                    return (
                        <a
                            href={pageHash(pageSize, index)}
                            aria-current="page"
                            className={classNames(index === currentPage ?
                                "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20")}
                            >
                            {index + 1}
                        </a>
                    );
                })}
                {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                <a
                href={pageHash(pageSize, currentPage + 1)}
                className={classNames("relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
                    currentPage === indicesToShow - 1 ? "pointer-events-none" : "",
                    currentPage === pageIndices.length - 1 ? "hidden" : "")}
                >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
            </nav>
            </div>
        </div>
    </div>
    );
}