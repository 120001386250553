import { USER_DETAILS_KEY } from './constants';
import { Component } from 'react';
import { UserDetails } from './types';
import axios from 'axios';
import * as amplitude from '@amplitude/analytics-browser';

const JWT_EMAIL_KEY = 'JWT_EMAIL_KEY';
let lookupProfile = async function() {
    let userDetails: UserDetails | null = null;  //JSON.parse(localStorage.getItem(USER_DETAILS_KEY));

    let update = async function() {
        if (axios.defaults.params && axios.defaults.params['email_jwt'] !== undefined) {
            axios.defaults.params['email_jwt'] = localStorage.getItem('JWT_EMAIL_KEY');
        }
        let response = await axios({
            url: '/user', 
            method: "get",
            withCredentials: true,
        });
        if (response.data.username !== '') {
            userDetails = {
                username: response.data.username,
                userImageUrl: response.data.image_url,
                email: response.data.email,
            };
            localStorage.setItem(JWT_EMAIL_KEY, response.data.email_jwt);
        } else {
            localStorage.setItem(JWT_EMAIL_KEY, '');
        }
    }
    await update();

    let getName = function() {
        if (userDetails === null) {
            return '';
        }
       return userDetails.username;
    };

    let getEmail = function() {
        if (userDetails === null) {
            return '';
        }
       return userDetails.email;
    };

    let getImageUrl = function() {
        if (userDetails === null) {
            return '';
        }
        return userDetails.userImageUrl;
    }
 
    let updateUser = function(newUserDetails: UserDetails) {
       userDetails = newUserDetails;
       localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(newUserDetails));
    };

    let isLoggedIn = function() {
        return userDetails !== null;
    }

    let logoutUser = function() {
        localStorage.clear();
        userDetails = null;
    }
 
    return {
       getName: getName,
       getImageUrl: getImageUrl,
       getEmail: getEmail,
       updateUser: updateUser,
       isLoggedIn: isLoggedIn,
       logoutUser: logoutUser,
       update: update,
    }
 
 };

 // Most components should use setLoginState instead. This is only meant if there's no component, e.g. /closeme
 export async function setUserInLocalStorage() {
    return lookupProfile();
 }

 export type LoginState = {
     loggedIn: boolean,
     username: string,
     email: string,
     userImageUrl: string,
     logoutFn: () => void,
 };

 export async function setLoginState(component: Component) {
    async function lookupAndApply() {
        console.log('looking up user');
        let profile = await lookupProfile();
        if (profile.isLoggedIn()) {
            // TODO: Amplitude recommends against user id as an identifier,
            // since it could be changed, but I don't have a good alternative,
            // and this degree of polish seems fine for quite a while.
            amplitude.setUserId(profile.getEmail());
        }
        component.setState({
            loggedIn: profile.isLoggedIn(),
            username: profile.getName(),
            email: profile.getEmail(),
            logoutFn: profile.logoutUser,
            userImageUrl: profile.getImageUrl(),
        });
    }
    await lookupAndApply();
    window.addEventListener('visibilitychange', (event) => {
        if (document.visibilityState === 'visible') {
            lookupAndApply();
        }
    });
 }

 export function userDetails(loginState: LoginState) {
    return {
        username: loginState.username,
        email: loginState.email,
        userImageUrl: loginState.userImageUrl,
    };
 }